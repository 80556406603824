import React, { useState } from "react";
import { Row, Col, Card, Button, Badge } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form } from "formik";
import FormController from "../../../common/FormController";
import { Spinner } from "react-bootstrap";
import CertificateCheckerService from "../../../../services/CertificateCheckerService";

const UploadCertificatePanel = ({
  uploadedFiles,
  setUploadedFiles,
  onAdded,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const initialValues = {
    filesToUpload: [],
  };
  async function onSubmit(values, { resetForm }) {
    setSubmitting(true);
    try {
      const formData = new FormData();
      // Populate formData with the values
      for (const value in values) {
        formData.append(value, values[value]);
      }
      for (let i = 0; i < values.filesToUpload.length; i++) {
        formData.append("file", values.filesToUpload[i]);
      }

     // setUploadedFiles(response.data);

      // const response = await CertificateCheckerService.checkCertificate(
      //   formData
      // );
      // console.log("response", response.data);
      // const passed = response.data.passed;
      // if (!passed) {
      //   alert("error");
      // } else {
      //   alert("success");
      // const analysis = await CertificateCheckerService.analyseCertificate(
      //   formData
      // );
      //setAnalysis(analysis.data);
      //console.log("analysis", analysis.data);
      //}
    } catch (error) {
      console.error("An error occurred", error);
    } finally {
    }
  }
  return (
    <Card className="shadow-sm bg-light">
      <Card.Header className="h4 text-black d-flex text-title justify-content-center align-items-center">
        <div className={`icon-shape rounded `}>
          <FontAwesomeIcon icon={faFileUpload} className="text-black" />
        </div>
        <span className="text-dark">Upload Certificates</span>
      </Card.Header>
      <Card.Body className="text-center">
        <Row>
          <Col md={12} className="mx-auto mb-4 p-4 pt-0">
            <p>Add new certificates to the certificate list to verify a document.</p>

            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {(props) => (
                <Form>
                  <Row className="mt-0">
                    <Col md={12}>
                      <FormController
                        control="multipleFileUpload"
                        onChange={(files) => {
                          props.setFieldValue("filesToUpload", files);
                          onAdded(files);
                        }}
                        allowedFileTypes="application/pdf"
                        capture="environment"
                        label="Add Certificates (PDF only)"
                        name="filesToUpload"
                        className="mb-3"
                        multiple={true}
                        showPanel={false}
                      />

                      {isSubmitting && (
                        <>
                          <Spinner
                            animation="border"
                            variant="primary"
                            size="sm"
                            className="me-1"
                          />
                          Please wait...
                        </>
                      )}
{/* 
                      <div className="form-group mt-3">
                        <Button
                          variant="dark"
                          className="btn-block"
                          type="submit"
                          disabled={
                            isSubmitting ||
                            props.values.filesToUpload.length <= 0
                          }
                        >
                          {isSubmitting ? "Please wait..." : "Verify"}
                        </Button>
                      </div> */}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default UploadCertificatePanel;
