import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Badge,
  Spinner,
  Dropdown,
  ButtonGroup,
  Modal,
} from "@themesberg/react-bootstrap";
import ReactJson from "react-json-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCode,
  faFileDownload,
  faFilePdf,
  faFileSignature,
  faEllipsisH,
  faTrash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, FieldArray } from "formik";
import { Alert, CardBody } from "react-bootstrap";
import FormController from "../../../common/FormController";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import PermissionCheck from "../../../../../auth/PermissionCheck";

const CertificateChecklist = ({
  checklist,
  checkResponses,
  isSubmitting,
  onAnalysis,
}) => {
  return (
    <Card border="light" className="shadow-sm h-100">
      <Card.Header className="h4 d-flex text-title justify-content-start align-items-center">
        <div className={`icon-shape rounded `}>
          <FontAwesomeIcon icon={faFileSignature} />
        </div>
        Certificate Checklist
      </Card.Header>
      <CardBody className="text-center">
        <div className="text-center">
          {checklist.length <= 0 && (
            <h5 className="text-gray-600 pt-4">
              No certificates have been uploaded yet
            </h5>
          )}
          {checklist.length > 0 && (
            <div className="text-start">
              {/* <p className="text-gray-600 ps-3">
              Select which certificates you wish to analyse
            </p> */}
              <ul className="mt-2">
                <Formik
                  initialValues={{
                    items: checklist ?? [],
                  }}
                  //onSubmit={onAnalysis}
                  enableReinitialize={true}
                >
                  {(props) => (
                    <Form>
                      {/* {JSON.stringify(props.values)} */}
                      <div className="d-flex justify-content-between align-items-center pt-2 pb-3">
                        <div className="text-start">
                          {props.values.checklist?.length ==
                          checklist.length ? (
                            <Button
                              variant="light"
                              size="sm"
                              className="d-inline"
                              disabled={isSubmitting}
                            >
                              Uncheck All
                            </Button>
                          ) : (
                            <Button
                              variant="light"
                              size="sm"
                              disabled={isSubmitting}
                            >
                              Check All
                            </Button>
                          )}
                        </div>
                        <div className="text-end">
                          {/* <Button
                            variant="primary"
                            size="sm"
                            type="submit"
                            className="d-inline ms-2"
                            disabled={
                              props.values.items.filter(
                                (item) => item.selected && !item.passed
                              ).length <= 0
                            }
                          >
                            Run checks (
                            {
                              props.values.items.filter(
                                (item) => item.selected && !item.passed
                              ).length
                            }{" "}
                            certificates)
                          </Button> */}
                          <Button
                            variant="secondary"
                            className="d-inline ms-2"
                            size="sm"
                            onClick={() =>
                              onAnalysis(props.values, props.setFieldValue)
                            }
                            disabled={
                              props.values.items.filter(
                                (item) =>
                                  item.selected &&
                                  !item.isExtracting &&
                                  !item.isAnalysisComplete
                              ).length <= 0 || isSubmitting
                            }
                          >
                            Verify (
                            {
                              props.values.items.filter(
                                (item) =>
                                  item.selected &&
                                  !item.isExtracting &&
                                  !item.isAnalysisComplete
                              ).length
                            }
                            &nbsp;certificates)
                          </Button>
                        </div>
                      </div>

                      <CertificateChecklistRow
                        items={props.values.items}
                        checkResponses={checkResponses}
                      />
                    </Form>
                  )}
                </Formik>
              </ul>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
const CertificateChecklistRowStatus = ({ item }) => {
  var variant = "light";
  item.isAnalysisComplete && item.passed && (variant = "success");
  item.errors?.length && !item.passed && !item.isError && (variant = "warning");
  item.isAnalysisComplete &&
    !item.passed &&
    item.isError &&
    (variant = "danger");

  var textcolor = "dark";
  item.isAnalysisComplete && item.passed && (textcolor = "white");
  item.errors?.length && !item.passed && (textcolor = "white");
  item.isAnalysisComplete && !item.passed && (textcolor = "white");

  var text = "Not checked yet";
  item.isAnalysisComplete && item.passed && (text = "Passed");
  !item.isAnalysisComplete && item.passed && (text = "Ready for analysis");
  item.errors?.length && !item.passed && !item.isError && (text = "Failed");
  item.errors?.length && !item.passed && item.isError && (text = "Error");
  item.isExtracting && (text = "Extracting data...");
  item.isVerifying && (text = "Verifying...");
  item.isRunningRules && (text = "Running rules...");

  return (
    <Badge
      bg={variant}
      className={`mx-2 p-2 px-3 rounded-pill text-${textcolor}`}
      size="lg"
    >
      <div className="d-flex">
        <div>
          {(item.isExtracting || item.isVerifying || item.isRunningRules) && (
            <Spinner
              animation="border"
              variant="primary"
              size="sm"
              className="me-2"
            />
          )}
        </div>
        <div>{text}</div>
      </div>
    </Badge>
  );
};

const handleFileChange = (file) => {
  const selectedFile = file;
  if (selectedFile) {
    // Create a temporary URL for the file
    const url = URL.createObjectURL(selectedFile);
    window.open(url, "_blank");
  }
};

const CertificateChecklistRow = ({ items, checkResponses }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseDataModal = () => setShowDataModal(false);

  return (
    <>
      <FieldArray
        name="items"
        render={({ insert, remove, push }) => (
          <div>
            {items?.length > 0 &&
              items?.map((item, index) => (
                <Row key={1 + index}>
                  <div className="h4 d-flex justify-content-start align-items-center">
                    <div>
                      <FormController
                        control="checkbox"
                        label={""}
                        disabled={
                          item.isExtracting || item.isExtracting || item.isVerifying || item.isRunningRules ||
                          item.isAnalysisComplete
                        }
                        name={`items[${index}].selected`}
                      />
                    </div>
                    <div>
                      <a onClick={() => handleFileChange(item.fullFile)}>
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          size="lg"
                          className="text-danger pointer-cursor"
                        />
                      </a>
                    </div>
                    <div className="ms-3 h5 w-100 d-flex justify-content-between align-items-center">
                      <div className="text-start fs-6">
                        {item?.data?.reference ? item?.data?.reference : item.filename }
                        <br />
                        <span className="fs-7 text-muted">
                          {item.size}
                          {item.certificateFullName}
                          {/* {item?.data?.certificateName != undefined && <>- {item?.data?.certificateName}</>}
                          {item?.data?.certificateVersion > 0.0 && <>- {item?.data?.certificateVersion}</>} */}
                        </span>
                      </div>
                      <div className="h5">
                        <CertificateChecklistRowStatus item={item} />
                        {/* <Button
                          variant="danger"
                          className="ms-3"
                          size="sm"
                          onClick={() => remove(index)}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="mx-2 text-white"
                          />
                        </Button> */}
                      </div>
                    </div>
                    <PermissionCheck
                      errorProps={{ disabled: true }}
                      requiresRole={["admin"]}
                    >
                      {item.isAnalysisComplete && (
                        <div>
                          <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle
                              as={Button}
                              split
                              variant="link"
                              className="text-dark m-0 p-0"
                            >
                              <span className="icon icon-sm">
                                <FontAwesomeIcon
                                  icon={faEllipsisH}
                                  className="icon-dark"
                                />
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=" dropdown-menu-ontop ">
                              <Dropdown.Item
                                onClick={() => setShowSuccessModal(true)}
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className="dropdown-item-icon"
                                />
                                View success messages
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setShowDataModal(true)}
                              >
                                <FontAwesomeIcon
                                  icon={faCode}
                                  className="dropdown-item-icon"
                                />
                                View data found
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <ModalSuccess
                            showModal={showSuccessModal}
                            handleCloseModal={handleCloseSuccessModal}
                            content={item?.successMessages}
                          />
                          <ModalData
                            showModal={showDataModal}
                            handleCloseModal={handleCloseDataModal}
                            content={item?.data}
                          />
                        </div>
                      )}
                    </PermissionCheck>
                  </div>

                  {/* //Error report */}
                  {item.errors?.length > 0 && (
                    <>
                      <p>
                        <div className="fw-bold d-block">
                          Found errors&nbsp;({item.errors?.length}):
                        </div>
                        {item.errors?.map((error, index) => (
                          <ul key={index} className="text-start list-unstyled">
                            <li className="text-start fs-7">
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="text-warning me-1"
                              />
                              {error}
                            </li>
                          </ul>
                        ))}
                      </p>
                    </>
                  )}

                  {/* //Success report */}
                  {/* {item.analysis?.successMessages.length > 0 && (
                    <>
                      <p>
                        <div className="fw-bold d-block">
                          Checks completed and passed ({item.analysis?.successMessages?.length}):
                        </div>
                        
                      </p>
                    </>
                  )} */}

                  <hr className="hr text-gray-600" />
                </Row>
              ))}
          </div>
        )}
      />
    </>
  );
};
export default CertificateChecklist;

const ModalSuccess = ({ showModal, handleCloseModal, content }) => {
  return (
    <Modal
      size="md"
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">Success Messages</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={() => handleCloseModal()}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="">
          {content?.map((message, index) => (
            <ul key={index} className="text-start list-unstyled">
              <li className="text-start fs-7">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-success me-1"
                />
                {message}
              </li>
            </ul>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
const PrettyPrintJson = ({ data }) => (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
);

const ModalData = ({ showModal, handleCloseModal, content }) => {
  return (
    <Modal
      size="md"
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header>
        <Modal.Title className="h6">Certificate Data</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={() => handleCloseModal()}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <ReactJson src={content} collapsed={2} theme="monokai" />
        </div>
      </Modal.Body>
    </Modal>
  );
};
