
import React from 'react';
import Meat8350 from "./Composite8350AttestationLabelsMeat"
import Dairy8350 from "./Composite8350AttestationLabelsDairy"
import Egg8350 from "./Composite8350AttestationLabelsEgg"
import Fishery8350 from "./Composite8350AttestationLabelsFishery"
import GelatineOrCollagen8350 from "./Composite8350AttestationLabelsGelatineOrCollagen"

export const HealthInformation = (props) => {
    const { } = props;
    return (
        <div>
            <h5>II. Health Attestation</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I am aware of the relevant requirements of Regulation (EC) No 178/2002 of the European Parliament and of the Council, Regulation (EC) No 852/2004 of the European Parliament and of the Council, Regulation
                    (EC) No 853/2004 of the European Parliament and of the Council, Regulation (EU) 2017/625 of the European Parliament and of the Council, Commission Delegated Regulations (EU) 2019/624 and (EU)
                    2022/2292, Commission Implementing Regulations (EU) 2019/627 and (EU) 2021/405.
                </p>
                <h6>II.2 The composite products described in Section A.2:</h6>
                <ol type='a'>
                    <li>comply with Article 5 of Regulation (EC) No 852/2004, in particular they come from (an) establishment(s) implementing a programme based
                        on the hazard analysis and critical control points (HACCP) principles, regularly audited by the competent authorities;</li>
                    <li>comply with Article 6(1)(b) of Regulation (EC) No 853/2004 on the origin of the products of animal origin used in their production</li>
                    <li>were produced in accordance with the requirements referred to under II.1.;</li>
                    <li>fulfil the guarantees covering live animals and products thereof provided by the control plan submitted in accordance with Article 6(2) of Delegated Regulation (EU) 2022/2292 and the concerned animals and products are listed in Annex -I to Implementing Regulation (EU)
                        2021/405 for the concerned third country or territory;</li>
                    <li>contain processed products of animal origin that were produced in the establishments located in the Member States or in the third countries authorised for the entry into the Union of those processed products of animal origin.</li>
                </ol>
            </>
        </div>
    );
}

export const CompositeGroupsAttestationContent = (props) => {
    const { attestationData, productionDateChoice, productionDate, productionDateSingle, productionDateMultipleStart, productionDateMultipleEnd, setFieldValue, isReadOnly } = props;

    return (
        attestationData !== undefined &&
        <>
            <h6>II.3. the composite products described in Section A.2 contain:</h6>
            <ul>
                <Meat8350
                    attestationData={attestationData}
                    isReadOnly={isReadOnly}
                />
                <Dairy8350
                    setFieldValue={setFieldValue}
                    attestationData={attestationData}
                    productionDateChoice={productionDateChoice}
                    productionDate={productionDate}
                    productionDateMultipleEnd={productionDateMultipleEnd}
                    productionDateSingle={productionDateSingle}
                    productionDateMultipleStart={productionDateMultipleStart}
                    isReadOnly={isReadOnly}
                />
                <Fishery8350
                    attestationData={attestationData}
                    isReadOnly={isReadOnly}
                />

                <Egg8350
                    attestationData={attestationData}
                    isReadOnly={isReadOnly}
                />
                <GelatineOrCollagen8350
                    attestationData={attestationData}
                    isReadOnly={isReadOnly}
                />
            </ul>
        </>
    )

}
