import React, { useEffect } from "react";
const FormScripts = (props) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Standard message for beforeunload events
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome
    };

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return <></>;
};
export default FormScripts;
