import * as Yup from "yup";

const validationMsgRequired = "This field is required";
const validationMsgAtLeastOne = "Select at least one of these options";
const validationMsgAtLeastTwo = "Select at least one option each for the selected Zone and for a Member State";

//Validation
export const validationSchema = Yup.object().shape({
  //Meat Data
  containsMeat: Yup.bool().test(
    "containsMeat",
    validationMsgAtLeastOne,
    function (x) {
      return (
        this.parent.containsDairy ||
        this.parent.containsEgg ||
        this.parent.containsMeat ||
        this.parent.containsFishery ||
        this.parent.containsColostrum ||
        this.parent.containsGelatineOrCollagen
      );
    }
  ),
  meatData: Yup.object()
    .nullable()
    .when("containsMeat", {
      is: (containsMeat) => containsMeat,
      then: Yup.object().shape({
        meatZoneOriginates: Yup.array()
        .min(1, validationMsgAtLeastOne)
        .required(validationMsgRequired),
      abattoirEstablishments: Yup.array()
        .nullable()
        .when(["speciesList", "meatZoneOriginates"], {
          is: (speciesList, meatZoneOriginates) =>
            Array.isArray(speciesList) &&
            speciesList.length > 0 &&
            Array.isArray(meatZoneOriginates) &&
            meatZoneOriginates.length > 0,
          then: Yup.array()
            .of(
              Yup.object().shape({
                approvalNumber: Yup.string().required(validationMsgRequired),
                name: Yup.string().required(validationMsgRequired),
              })
            )
            .min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        }),
        originatesFrom1: Yup.bool().test(
          "meatData.originatesFrom1",
          validationMsgAtLeastOne,
          function (x) {
            return (
              this.parent.originatesFrom1 ||
              this.parent.originatesFrom2 ||
              this.parent.originatesFrom3
            );
          }
        ),
        originatesFrom2: Yup.bool().test(
          "meatData.originatesFrom2",
          validationMsgAtLeastOne,
          function (x) {
            return (
              this.parent.originatesFrom1 ||
              this.parent.originatesFrom2 ||
              this.parent.originatesFrom3
            );
          }
        ),
        originatesFrom3: Yup.bool().test(
          "meatData.originatesFrom3",
          validationMsgAtLeastOne,
          function (x) {
            return (
              this.parent.originatesFrom1 ||
              this.parent.originatesFrom2 ||
              this.parent.originatesFrom3
            );
          }
        ),
        speciesList: Yup.array()
          .nullable()
          .of(
            Yup.object().shape({
              speciesGroupName: Yup.string().required(validationMsgRequired),
              speciesData: Yup.array().of(
                Yup.object().shape({
                  treatment: Yup.string().required(validationMsgRequired),
                  establishmentCodeEstablishments: Yup.array()
                    .min(1, validationMsgAtLeastOne)
                    .required(validationMsgRequired),
                  countryOfOrigin: Yup.array()
                    .of(
                      Yup.object().shape({
                        id: Yup.string().required(),
                        countryName: Yup.string().required(),
                        isoCode: Yup.string().required(),
                      })
                    )
                    .test(
                      "countryOfOrigin",
                      validationMsgAtLeastOne,
                      function (x) {
                        return !this.disable;
                      }
                    )
                    .min(1, validationMsgAtLeastOne),
                })
              ),
            })
          )
          .min(1, validationMsgAtLeastOne),

        BseRiskCategoryNegligible: Yup.bool().test(
          "BseRiskCategoryNegligible",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.containsBov ||
              this.parent.containsOvi ||
              this.parent.containsRuf ||
              this.parent.containsRuw
              ? this.parent.BseRiskCategoryNegligible ||
              this.parent.BseRiskCategoryControlled ||
              this.parent.BseRiskCategoryUndetermined
              : true;
          }
        ),
        BseRiskCategoryControlled: Yup.bool().test(
          "BseRiskCategoryControlled",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.containsBov ||
              this.parent.containsOvi ||
              this.parent.containsRuf ||
              this.parent.containsRuw
              ? this.parent.BseRiskCategoryNegligible ||
              this.parent.BseRiskCategoryControlled ||
              this.parent.BseRiskCategoryUndetermined
              : true;
          }
        ),
        BseRiskCategoryUndetermined: Yup.bool().test(
          "BseRiskCategoryUndetermined",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.containsBov ||
              this.parent.containsOvi ||
              this.parent.containsRuf ||
              this.parent.containsRuw
              ? this.parent.BseRiskCategoryNegligible ||
              this.parent.BseRiskCategoryControlled ||
              this.parent.BseRiskCategoryUndetermined
              : true;
          }
        ),
        BseRiskCategoryNegligibleStatements1: Yup.array().nullable()
          .when("BseRiskCategoryNegligible", {
            is: true,
            then: Yup.array().nullable().min(1, validationMsgAtLeastOne)
          }),
        // BseRiskCategoryNegligibleStatements2: Yup.array().nullable()
        //   .when("BseRiskCategoryNegligible", {
        //     is: true,
        //     then: Yup.array().nullable().min(1, validationMsgAtLeastOne)
        //   }),
        BseRiskCategoryControlledStatements: Yup.array().nullable()
          .when("BseRiskCategoryControlled", {
            is: true,
            then: Yup.array().nullable().min(1, validationMsgAtLeastOne)
          }),
        BseRiskCategoryControlledCStatements: Yup.array().nullable()
          .when("BseRiskCategoryControlled", {
            is: true,
            then: Yup.array().nullable().min(1, validationMsgAtLeastOne)
          }),
        BseRiskCategoryControlled4Statements: Yup.array().nullable()
          .when("BseRiskCategoryControlledStatements", {
            is: (array) => array?.includes("4") || false, // Added null-safe operator (?.) and default to false
            then: Yup.array().nullable().of(Yup.number()).min(1, validationMsgAtLeastOne)
          }),
        BseRiskCategoryUndeterminedStatements: Yup.array().nullable()
          .when("BseRiskCategoryUndetermined", {
            is: true,
            then: Yup.array().nullable().min(1, validationMsgAtLeastOne)
          }),
        BseRiskCategoryUndetermined4Statements: Yup.array().nullable()
          .when("BseRiskCategoryUndeterminedStatements", {
            is: (array) => array?.includes("4") || false, // Added null-safe operator (?.) and default to false
            then: Yup.array().nullable().of(Yup.number()).min(1, validationMsgAtLeastOne)
          })
      }),
    }),
  //Egg Data
  containsEgg: Yup.bool().test(
    "containsEgg",
    validationMsgAtLeastOne,
    function (x) {
      return (
        this.parent.containsMeat ||
        this.parent.containsDairy ||
        this.parent.containsEgg ||
        this.parent.containsFishery ||
        this.parent.containsColostrum ||
        this.parent.containsGelatineOrCollagen
      );
    }
  ),
  eggData: Yup.object()
    .nullable()
    .when("containsEgg", {
      is: (containsEgg) => containsEgg,
      then: Yup.object().shape({
        eggEstablishments: Yup.array()
          .min(1, validationMsgAtLeastOne)
          .required(validationMsgRequired)
          .test(
            "eggEstablishments-length",
            validationMsgAtLeastTwo,
            function (value) {
              return !(this.parent.eggOriginatesFrom1 && this.parent.eggOriginatesFrom2) || (value && value.length >= 2);
            }
          ),
        eggOriginatesFrom1: Yup.bool().test(
          "eggData.eggOriginatesFrom1",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.eggOriginatesFrom1 || this.parent.eggOriginatesFrom2;
          }
        ),
        eggOriginatesFrom2: Yup.bool().test(
          "eggData.eggOriginatesFrom2",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.eggOriginatesFrom1 || this.parent.eggOriginatesFrom2;
          }
        ),
        eggCountryOrigin: Yup.array().when("eggOriginatesFrom1", {
          is: true,
          then: Yup.array()
            .min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        }),
        eggStatementsA: Yup.number().min(1, validationMsgAtLeastOne),
        egg_A_2: Yup.number().when("eggStatementsA", {
          is: 2,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        egg_A_2_1: Yup.number().when("egg_A_2", {
          is: 1,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        egg_A_2_3: Yup.number().when("egg_A_2", {
          is: 3,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        egg_A_2_4: Yup.number().when("egg_A_2", {
          is: 4,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        egg_A_2_5: Yup.number().when("egg_A_2", {
          is: 5,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        eggStatementsB: Yup.number().min(1, validationMsgAtLeastOne),
        egg_B_2: Yup.number().when("eggStatementsB", {
          is: 2,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        egg_B_2_1: Yup.number().when("egg_B_2", {
          is: 1,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        egg_B_2_4: Yup.number().when("egg_B_2", {
          is: 4,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
      }),
    }),

  //Fishery Data
  containsFishery: Yup.bool().test(
    "containsFishery",
    validationMsgAtLeastOne,
    function (x) {
      return (
        this.parent.containsMeat ||
        this.parent.containsDairy ||
        this.parent.containsFishery ||
        this.parent.containsEgg ||
        this.parent.containsColostrum ||
        this.parent.containsGelatineOrCollagen
      );
    }
  ),
  fisheryCountryOrigin: Yup.array().when('containsFishery', {
    is: true,
    then: Yup.array().test(
      'fishery-country-origin-required',
      'Please select at least one country of origin for either Fishery or Bivalve Molluscs',
      function (value) {
        const bivalveHasEntries =
          Array.isArray(this.parent.bivalveMolluscsCountryOrigin) && this.parent.bivalveMolluscsCountryOrigin.length > 0;
        if ((Array.isArray(value) && value.length > 0) || bivalveHasEntries) {
          return true;
        }
        return this.createError();
      }
    ),
    otherwise: Yup.array().notRequired(),
  }),
  bivalveMolluscsCountryOrigin: Yup.array().when('containsFishery', {
    is: true,
    then: Yup.array().test(
      'bivalve-country-origin-required',
      'Please select at least one country of origin for either Fishery or Bivalve Molluscs',
      function (value) {
        const fisheryHasEntries =
          Array.isArray(this.parent.fisheryCountryOrigin) && this.parent.fisheryCountryOrigin.length > 0;
        if ((Array.isArray(value) && value.length > 0) || fisheryHasEntries) {
          return true;
        }
        return this.createError();
      }
    ),
    otherwise: Yup.array().notRequired(),
  }),
  fisheryEstablishmentCodeEstablishments: Yup.array().when('containsFishery', {
    is: true,
    then: Yup.array().test(
      'fishery-establishments-required',
      validationMsgAtLeastOne,
      function (value) {
        const { fisheryCountryOrigin } = this.parent;
        if (Array.isArray(fisheryCountryOrigin) && fisheryCountryOrigin.length > 0) {
          return Array.isArray(value) && value.length > 0;
        }
        return true; // Pass validation if fisheryCountryOrigin is empty or containsFishery is false
      }
    ),
    otherwise: Yup.array().notRequired(),
  }),
  bivalveMolluscsEstablishmentCodeEstablishments: Yup.array().when('containsFishery', {
    is: true,
    then: Yup.array().test(
      'bivalveMolluscs-establishments-required',
      validationMsgAtLeastOne,
      function (value) {
        const { bivalveMolluscsCountryOrigin } = this.parent;
        if (Array.isArray(bivalveMolluscsCountryOrigin) && bivalveMolluscsCountryOrigin.length > 0) {
          return Array.isArray(value) && value.length > 0;
        }
        return true; // Pass validation if bivalveMolluscsCountryOrigin is empty or containsFishery is false
      }
    ),
    otherwise: Yup.array().notRequired(),
  }),


  //Dairy Data
  containsDairy: Yup.bool().test(
    "containsDairy",
    validationMsgAtLeastOne,
    function (x) {
      return (
        this.parent.containsMeat ||
        this.parent.containsEgg ||
        this.parent.containsFishery ||
        this.parent.containsDairy ||
        this.parent.containsColostrum ||
        this.parent.containsGelatineOrCollagen
      );
    }
  ),
  dairyData: Yup.object()
    .nullable()
    .when("containsDairy", {
      is: (containsDairy) => containsDairy,
      then: Yup.object().shape({
        dairyA_1: Yup.bool().test(
          "dairyA_1",
          validationMsgAtLeastOne,
          function (x) {
            return !this.parent.dairyA_1 &&
              !this.parent.dairyA_2 &&
              !this.parent.dairyA_3
              ? false
              : true;
          }
        ),
        dairyA_2: Yup.bool().test(
          "dairyA_2",
          validationMsgAtLeastOne,
          function (x) {
            return !this.parent.dairyA_1 &&
              !this.parent.dairyA_2 &&
              !this.parent.dairyA_3
              ? false
              : true;
          }
        ),
        dairyA_3: Yup.bool().test(
          "dairyA_3",
          validationMsgAtLeastOne,
          function (x) {
            return !this.parent.dairyA_1 &&
              !this.parent.dairyA_2 &&
              !this.parent.dairyA_3
              ? false
              : true;
          }
        ),
        dairyAapprovalNumberEstablishments: Yup.array()
          .min(1, validationMsgAtLeastOne)
          .required(validationMsgRequired),
        dairyBOriginatesIn: Yup.array().min(1, validationMsgAtLeastOne),
        dairyCMilkType1: Yup.bool().test(
          "dairyData.dairyCMilkType5",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.dairyCMilkObtainedFrom &&
              this.parent.dairyCStatements == 1 &&
              !this.parent.dairyCMilkType1 &&
              !this.parent.dairyCMilkType2 &&
              !this.parent.dairyCMilkType3 &&
              !this.parent.dairyCMilkType4 &&
              !this.parent.dairyCMilkType5
              ? false
              : true;
          }
        ),
        dairyCMilkType2: Yup.bool().test(
          "dairyData.dairyCMilkType5",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.dairyCMilkObtainedFrom &&
              this.parent.dairyCStatements === 1 &&
              !this.parent.dairyCMilkType1 &&
              !this.parent.dairyCMilkType2 &&
              !this.parent.dairyCMilkType3 &&
              !this.parent.dairyCMilkType4 &&
              !this.parent.dairyCMilkType5
              ? false
              : true;
          }
        ),
        dairyCMilkType3: Yup.bool().test(
          "dairyData.dairyCMilkType5",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.dairyCMilkObtainedFrom &&
              this.parent.dairyCStatements === 1 &&
              !this.parent.dairyCMilkType1 &&
              !this.parent.dairyCMilkType2 &&
              !this.parent.dairyCMilkType3 &&
              !this.parent.dairyCMilkType4 &&
              !this.parent.dairyCMilkType5
              ? false
              : true;
          }
        ),
        dairyCMilkType4: Yup.bool().test(
          "dairyData.dairyCMilkType5",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.dairyCMilkObtainedFrom &&
              this.parent.dairyCStatements === 1 &&
              !this.parent.dairyCMilkType1 &&
              !this.parent.dairyCMilkType2 &&
              !this.parent.dairyCMilkType3 &&
              !this.parent.dairyCMilkType4 &&
              !this.parent.dairyCMilkType5
              ? false
              : true;
          }
        ),
        dairyCMilkType5: Yup.bool().test(
          "dairyData.dairyCMilkType5",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.dairyCMilkObtainedFrom &&
              this.parent.dairyCStatements === 1 &&
              !this.parent.dairyCMilkType1 &&
              !this.parent.dairyCMilkType2 &&
              !this.parent.dairyCMilkType3 &&
              !this.parent.dairyCMilkType4 &&
              !this.parent.dairyCMilkType5
              ? false
              : true;
          }
        ),
        dairyCStatements: Yup.number().when("dairyCMilkObtainedFrom", {
          is: true,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        dairyC_1: Yup.number().when("dairyCStatements", {
          is: 1,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        dairyC_2: Yup.number().when("dairyCStatements", {
          is: 2,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        dairyC_1_6: Yup.number().when("dairyC_1", {
          is: 6,
          then: Yup.number().integer().min(1, validationMsgAtLeastOne),
        }),
        dairyACountryOrigin: Yup.array().test(
          "dairyA_1",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.dairyA_1 == true && x.length === 0
              ? false
              : true;
          }
        ),
        dairyA2CountryOrigin: Yup.array().test(
          "dairyA_2",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.dairyA_2 == true && x.length === 0
              ? false
              : true;
          }
        ),
        dairyCMilkObtainedFrom: Yup.bool().test(
          "dairyCMilkObtainedFrom",
          validationMsgAtLeastOne,
          function (x) {
            return this.parent.dairyCMilkObtainedFrom || this.parent.dairyD;
          }
        ),
      }),
    }),
  // Colostrum Data
  containsColostrum: Yup.bool().test(
    "containsColostrum",
    validationMsgAtLeastOne,
    function (x) {
      return (
        this.parent.containsMeat ||
        this.parent.containsDairy ||
        this.parent.containsEgg ||
        this.parent.containsFishery ||
        this.parent.containsColostrum ||
        this.parent.containsGelatineOrCollagen
      );
    }
  ),
  colostrumD: Yup.bool()
    .test('colostrumD', validationMsgRequired, function (x) {
      return (this.parent.containsColostrum === true && x === false)
        ? false : true;
    }),
  // Gelatine or Collagen Data
  containsGelatineOrCollagen: Yup.bool().test(
    "containsGelatineOrCollagen",
    validationMsgAtLeastOne,
    function (x) {
      return (
        this.parent.containsMeat ||
        this.parent.containsDairy ||
        this.parent.containsEgg ||
        this.parent.containsFishery ||
        this.parent.containsColostrum ||
        this.parent.containsGelatineOrCollagen
      );
    }
  ),
  gelatineOrCollagenData: Yup.object()
    .nullable()
    .when("containsGelatineOrCollagen", {
      is: (containsGelatineOrCollagen) => containsGelatineOrCollagen,
      then: Yup.object().shape({
        establishments: Yup.array()
          .min(1, validationMsgAtLeastOne)
          .required(validationMsgRequired),
        gelatineOrCollagenCountryOrigin: Yup.array()
          .min(1, validationMsgAtLeastOne)
          .required(validationMsgRequired),
        BseRiskCategoryNegligible: Yup.bool().test(
          "BseRiskCategoryNegligible",
          validationMsgAtLeastOne,
          function (x) {
            return (
              this.parent.BseRiskCategoryNegligible ||
              this.parent.BseRiskCategoryControlled ||
              this.parent.BseRiskCategoryUndetermined
            );
          }
        ),
        BseRiskCategoryControlled: Yup.bool().test(
          "BseRiskCategoryControlled",
          validationMsgAtLeastOne,
          function (x) {
            return (
              this.parent.BseRiskCategoryNegligible ||
              this.parent.BseRiskCategoryControlled ||
              this.parent.BseRiskCategoryUndetermined
            );
          }
        ),
        BseRiskCategoryUndetermined: Yup.bool().test(
          "BseRiskCategoryUndetermined",
          validationMsgAtLeastOne,
          function (x) {
            return (
              this.parent.BseRiskCategoryNegligible ||
              this.parent.BseRiskCategoryControlled ||
              this.parent.BseRiskCategoryUndetermined
            );
          }
        ),
        BseRiskCategoryNegligibleStatements: Yup.array()
          .nullable()
          .when("BseRiskCategoryNegligible", {
            is: true,
            then: Yup.array().min(1, validationMsgAtLeastOne),
          }),
        BseRiskCategoryControlledStatements: Yup.array()
          .nullable()
          .when("BseRiskCategoryControlled", {
            is: true,
            then: Yup.array().min(1, validationMsgAtLeastOne),
          }),
      }),
    }),
});
