import React, {useEffect} from 'react';
import * as AttestationContent from "../../../Common/AttestationContent";
import {  useFormikContext } from 'formik';
import AttestationBody from "../../../Common/AttestationBody";
import {  Button,Card, Col, Row } from "@themesberg/react-bootstrap";
import { DateCollectionProduction } from "../../../../../../components/DateCollectionProduction";

export default function ResponseAttestationNonGEFSForm(props) {
  const { values: formValues } = useFormikContext();
  const {
    formField: {
      traceability,
      batchNumber,
      finalConsumer,
      dateCollectionProduction,
      productionDate,
      productionDateSingle,
      productionDateMultipleStart,
      productionDateMultipleEnd,
      dateInspection,
      approvedExportCountries,
      approvedExportCountriesOfOrigin,
      numberProductsCases,
      hatcheryName,
      hatcheries,
      isDateCollectionProductionMulti,
      dateCollectionProductionStart,
      dateCollectionProductionEnd,
      dateCollectionProductionSingle,
    },
    setFieldValue,
    initialInspectionDate,
    uniqueRef,
    product, 
    manufacturer,
    platform,
    site, 
    user,
    productDetail,
    speciesList, 
    attestationData,
    certificateNumber,
    productId,
    organisation,
    isGEFS,                     
    temperature                            

  } = props;
  const productionDateChoice =[{value:1, key: "Single production dates"},{value:2, key: "Multiple production dates"}];
  

  useEffect(() => {
    {formValues.dateInspection===""&&  setFieldValue("dateInspection",initialInspectionDate)}
  },[initialInspectionDate,]);


  return (
    <Row className="justify-content-left mt-4">
      <Col xl={11}>
        <Card border="light" className="bg-white shadow-sm mb-4 p-3">
          <Card.Body>
            <AttestationBody
                finalConsumer={finalConsumer} 
                traceability={traceability} 
                isReadOnly={false}  
                uniqueRef={uniqueRef}
                attestationData={attestationData}
                productId={productId} 
                user={user} 
                organisation={organisation}
                manufacturer={manufacturer}
                platform={platform}
                site={site}
                setFieldValue={setFieldValue}
                productionDateChoice={productionDateChoice}
                productionDate={productionDate}
                productionDateMultipleEnd={productionDateMultipleEnd}
                productionDateSingle={productionDateSingle}
                productionDateMultipleStart={productionDateMultipleStart}
                isGEFS={isGEFS}
                product={product} 
                productDetail={productDetail} 
                certificateNumber={certificateNumber}
            />
                                
          <Row className="mt-3">
              <Col md={12}>
                  <AttestationContent.Traceability 
                      traceability={traceability} 
                      isReadOnly={false}  
                  />
                  <AttestationContent.TraceabilityTable 
                      dateCollectionProduction={dateCollectionProduction} 
                      finalConsumer={finalConsumer} 
                      certificateNumber={certificateNumber}
                      isReadOnly={false}  
                  />
                  <AttestationContent.AttestationNonGEFSQuestions 
                      approvedExportCountries={approvedExportCountries}  
                      approvedExportCountriesOfOrigin={approvedExportCountriesOfOrigin}
                      numberProductsCases={numberProductsCases}
                      hatcheryName={hatcheryName}
                      hatcheries={hatcheries}
                      certificateNumber={certificateNumber}
                  />

                  <AttestationContent.CertificateFormDetails 
                      certificateNumber={certificateNumber}
                      manufacturer={manufacturer} 
                      productDetail={productDetail}
                      attestationData={attestationData}
                      isGEFS={product.isGEFS}
                      site={site}
                      temperature={temperature}                            
                  />
                {certificateNumber != 8371 && (
                  <DateCollectionProduction
                    certificateNumber={certificateNumber} // Passing certificate number as a prop
                    isDateCollectionProductionMultiName={isDateCollectionProductionMulti.name}
                    isDateCollectionProductionMultiLabel={isDateCollectionProductionMulti.label}
                    dateCollectionProductionStartName={dateCollectionProductionStart.name}
                    dateCollectionProductionStartLabel={dateCollectionProductionStart.label}
                    dateCollectionProductionEndName={dateCollectionProductionEnd.name}
                    dateCollectionProductionEndLabel={dateCollectionProductionEnd.label}
                    dateCollectionProductionSingleName={dateCollectionProductionSingle.name}
                    dateCollectionProductionSingleLabel={dateCollectionProductionSingle.label}
                    dateCollectionProductionName={dateCollectionProduction.name}
                    isReadOnly={false}
                  />
                )}
              </Col>
          </Row>

          <Row className="mt-3">
              <Col md={12}>
                  <AttestationContent.Batches
                      batchNumber={batchNumber}
                      isReadOnly={false}   
                      isGEFS={false}
                  />
              </Col>
          </Row>
          
          <Row className="mt-3">
          
        </Row>
              <h4>Vet</h4>
              <ul className="list-unstyled">
                <li>Signed name: {user.fullName}</li>
                <li>RCVS number: {user.rcvs}</li>
                <li>Practice name: {user.practiceName}</li>
                <li>Email address: {user.email}</li>
                <li>Address: {user.address}</li>
              </ul>

          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
