import React  from 'react';
import {Card, Col, Row, Image } from "@themesberg/react-bootstrap";
import { SupplierSignature, CertificateFormDetails, ConsignmentDetails, ExporterIntro } from '../Components/TemplateContent';
import { useFormikContext } from 'formik';
import moment from 'moment';

export default function ExporterDeclarationTemplate(props) {;
  const {
    user,
    forwardedRef,
    uniqueRef,
    product, 
    manufacturer,
    site, 
    attestationData,
    productDetail,
    organisation,
    platform,
    temperature,
    showPreview = false
  } = props;

  const { values: formValues } = useFormikContext();
  
  return (
    <Card ref={forwardedRef} border="light" className={`${!showPreview && "d-none"} d-print-block bg-white shadow-sm mb-4 p-3`}>
            <Card.Body>
                <ExporterIntro uniqueRef={uniqueRef} user={user} manufacturer={manufacturer} organisation={organisation} platform={platform} />
                <Row>
                    <Col md={12}>
                        <ConsignmentDetails
                            dateCollectionProduction={"N/A"}
                            languageCode={formValues.languageCode}
                            finalConsumer={formValues.finalConsumer}
                            batches={formValues.batches}
                            certificateFormData={attestationData[8461]}
                            manufacturer={manufacturer} 
                            productDetail={productDetail}
                            numberPackages={formValues.numberPackages}
                            invoiceNumber={formValues.invoiceNumber}
                            netWeight={formValues.netWeight}
                            grossWeight={formValues.grossWeight}
                            product={product}
                            site={site}
                            platform={platform}
                            temperature={temperature}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={12}>
                        <CertificateFormDetails 
                            coldStores={attestationData[8461].coldStoreEstablishments}
                            languageCode={formValues.languageCode}
                            site={site}
                            platform={platform}
                            temperature={temperature}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={12}>
                        <SupplierSignature
                            languageCode={formValues.languageCode}
                            user={user}
                            date={props.value}
                            manufacturer={manufacturer}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
  );
}
