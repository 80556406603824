import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import * as Composite8350Labels from "../Composite8350Labels"
import { findArrayElementByValue, findArrayElementByFieldNameAndValue } from "../../../Attestations/Common/AttestationFunctions";
const GelatineOrCollagen8350 = (props) => {
    const { attestationData } = props;
    let compositeLabels = Composite8350Labels;
    return (
        <>
            {attestationData?.containsGelatineOrCollagen ? (
                <li>
                    <strong>
                        <u>II.3E Gelatine or collagen derived from ruminant bones</u>
                    </strong>
                    <ol>
                        <li>
                            <>
                                that originate from the approved establishment No. <strong><u>{attestationData.gelatineOrCollagenData?.establishments.map(establishment => establishment.approvalNumber).join(", ")}</u></strong> situated in the country <strong><u>{attestationData.gelatineOrCollagenData?.gelatineOrCollagenCountryOrigin?.map(x => x.isoCode).join(", ")}</u></strong>;
                            </>
                        </li>
                        <li>for which:
                            {/* No BSE Statements*/}
                            {!attestationData.gelatineOrCollagenData.bseRiskCategoryNegligible &&
                                !attestationData.gelatineOrCollagenData.bseRiskCategoryControlled &&
                                !attestationData.gelatineOrCollagenData.bseRiskCategoryUndetermined &&
                                <p>n/a</p>}

                            {/* BSE Negligible*/}
                            {attestationData.gelatineOrCollagenData.bseRiskCategoryNegligible &&
                                <ul>
                                    <li>the country or region of origin is classified in accordance with Decision 2007/453/EC as a country or region posing a <u> negligible bovine spongiform encephalopathy (BSE) risk</u>, and
                                        <ul>
                                            {attestationData.gelatineOrCollagenData.bseRiskCategoryNegligibleStatements.map(statement =>
                                                <li>
                                                    {findArrayElementByValue(compositeLabels.BseRiskCategoryNegligibleStatementsChoiceGel, statement)}
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                </ul>
                            }
                            {/* BSE Controlled */}
                            {attestationData.gelatineOrCollagenData.bseRiskCategoryControlled &&
                                <ul>
                                    <li>the country or region of origin is classified in accordance with Decision 2007/453/EC as a country or region posing a <u>controlled BSE risk</u>, and:
                                        <ul>
                                            {attestationData.gelatineOrCollagenData.bseRiskCategoryControlledStatements.length > 0 &&
                                                <>
                                                    <li>
                                                        {findArrayElementByFieldNameAndValue(compositeLabels.BseRiskCategoryControlledStatementsChoiceGel,
                                                            'gelatineOrCollagenData.BseRiskCategoryControlledStatements', 1)}
                                                    </li>
                                                    <li>
                                                        {findArrayElementByFieldNameAndValue(compositeLabels.BseRiskCategoryControlledStatementsChoiceGel,
                                                            'gelatineOrCollagenData.BseRiskCategoryControlledStatements', 2)}
                                                    </li>
                                                    <li>
                                                        {compositeLabels.BseRiskCategoryControlledStatementsChoiceGel.map((item) => (
                                                            attestationData.gelatineOrCollagenData.bseRiskCategoryControlledStatements.includes(item.value) && (
                                                                <li key={item.value}>
                                                                    {ReactHtmlParser(item.key)}
                                                                </li>
                                                            )
                                                        ))}
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            }
                            {/* BSE Undetermined */}
                            {attestationData.gelatineOrCollagenData.bseRiskCategoryUndetermined &&
                                <ul>
                                    <li>the country or region of origin is classified in accordance with Decision 2007/453/EC as a country or region with an <u>undetermined BSE risk</u>, and
                                        <ul>
                                            {attestationData.gelatineOrCollagenData.bseRiskCategoryUndeterminedStatements.length > 0 &&
                                                <li>
                                                    {compositeLabels.BseRiskCategoryUndeterminedStatementsChoiceGel.map((item) => (
                                                        attestationData.gelatineOrCollagenData.bseRiskCategoryUndeterminedStatements.includes(item.value) && (
                                                            <li key={item.value}>
                                                                {ReactHtmlParser(item.key)}
                                                            </li>
                                                        )
                                                    ))}
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            }
                        </li>
                    </ol>
                </li>
            )
                :
                (
                    <li>II.3.E Does not contain Gelatine or collagen derived from ruminant bones</li>
                )
            }
        </>
    );
};

export default GelatineOrCollagen8350 