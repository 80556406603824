import React from "react";
import { useDropzone } from "react-dropzone";
import { useFormikContext, useField } from "formik";
import { Col, Row, Image, CloseButton } from "@themesberg/react-bootstrap";
import {
  faFilePdf,
  faFile,
  faPlus,
  faFileImage,
  faFileExcel,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MultipleFileUpload(props) {
  const formik = useFormikContext();
  const [{ value }, , { setValue }] = useField(props.name);
  const allowedFileTypes = props.allowedFileTypes;
  const disabled = props.disabled;
  const accept = props.accept;
  const showPanel = props.showPanel ?? true;
  let label = "Drag and drop, or click to add some files";
  if (props.label != null && props.label !== undefined) {
    label = props.label;
  }
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    multiple,
    col = 12,
    size,
    ...rest
  } = useDropzone({
    accept: allowedFileTypes,
    onDrop: (acceptedFiles) => {
      const files = multiple ? value.concat(acceptedFiles) : acceptedFiles;
      if (props.setUploadedFileCount !== undefined) {
        getTotalFilesSizeMessage(files);
      }
      setValue(files);
      props.onChange(files);
    },
  });

  const getTotalFilesSizeMessage = (files) => {
    let totalSize = 0;
    let i;
    if (typeof files[0].size === "number") {
      for (i = 0; i < files.length; i++) {
        if (files[i].size) {
          totalSize += files[i].size || 0;
        }
      }
    } else {
      return "";
    }
    totalSize /= 1024;
    if (totalSize < 1024) {
      return props.setUploadedFileCount(totalSize?.toFixed(2));
    } else {
      props.setUploadedFileCount((totalSize / 1024)?.toFixed(2));
    }
  };

  let filesImages = {
    icon: faFileImage,
    colour: "text-purple",
    extensions: ["jpg", "png", "bmp", "tiff"],
  };
  let filesWord = {
    icon: faFileWord,
    colour: "text-blue",
    extensions: ["docx", "doc"],
  };
  let filesPdf = {
    icon: faFilePdf,
    colour: "text-danger",
    extensions: ["pdf"],
  };
  let filesExcel = {
    icon: faFileExcel,
    colour: "text-success",
    extensions: ["xlsx", "xls", "csv"],
  };

  function remove(i) {
    acceptedFiles.splice(i, 1);
  }
  const getExtension = (file) => {
    return file.name.split(".").pop().toLowerCase();
  };
  const getPreviewImg = (file) => {
    return URL.createObjectURL(file);
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + "" + sizes[i];
  }

  const files = acceptedFiles.map((file, i) =>
    props.imageUploader ? (
      <Col md={4} key={`image-${i}`} className="position-relative">
        <Image
          className="img-upload-preview img-thumbnail img-fluid"
          alt="image"
          src={getPreviewImg(file)}
        />
        <a
          href="#"
          className="position-absolute end-0 mx-3"
          onClick={() => remove(i)}
        >
          <CloseButton />
        </a>

        <div className="text text-truncate ">
          <span>{file.path}</span>
          <br />
          <small>{formatBytes(file.size, null)}</small>
        </div>
      </Col>
    ) : (
      <Col
        xs={12}
        md={col}
        key={`file-${file.name}-${i}`}
        className="dropzone-upload"
      >
        {filesPdf.extensions.includes(getExtension(file)) ? (
          <div>
            <FontAwesomeIcon
              icon={filesPdf.icon}
              className={`mx-auto ${filesPdf.colour} `}
            />
            <div className="text">
              <span>{file.path}</span>
              <br />
              <small>{formatBytes(file.size, null)}</small>
            </div>
          </div>
        ) : filesWord.extensions.includes(getExtension(file)) ? (
          <div>
            <FontAwesomeIcon
              icon={filesWord.icon}
              className={`mx-auto ${filesWord.colour} `}
            />
            <div className="text">
              <span>{file.path}</span>
              <br />
              <small>{formatBytes(file.size, null)}</small>
            </div>
          </div>
        ) : filesImages.extensions.includes(getExtension(file)) ? (
          <div>
            <FontAwesomeIcon
              icon={filesImages.icon}
              className={`mx-auto ${filesImages.colour} `}
            />
            <div className="text">
              <span>{file.path}</span>
              <br />
              <small>{formatBytes(file.size, null)}</small>
            </div>
          </div>
        ) : filesExcel.extensions.includes(getExtension(file)) ? (
          <div>
            <FontAwesomeIcon
              icon={filesExcel.icon}
              className={`mx-auto ${filesExcel.colour} `}
            />
            <div className="text">
              <span>{file.path}</span>
              <br />
              <small>{formatBytes(file.size, null)}</small>
            </div>
          </div>
        ) : (
          <div>
            <FontAwesomeIcon icon={faFile} className="mx-auto text-grey " />
            {file.path}
            <br />
            <div className="text">
              <small>{formatBytes(file.size, null)}</small>
            </div>
          </div>
        )}
      </Col>
    )
  );

  const fileChangedHandler = (event) => {
    // alert(event.target.files[0].size);
    if (props.setUploadedFileCount !== null) {
      props.setUploadedFileCount(event.target.files[0].size);
    }
  };
  // console.log("Formik values", formik.values);
  return (
    <Row className={`mt-0`}>
      <Col xs={12} lg={12} xl={12}>
        <div
          {...getRootProps({
            className: `dropzone ${disabled ? "opacity-25" : ""}`,
          })}
        >
          <input disabled={disabled} {...getInputProps()} accept={accept} />
          <FontAwesomeIcon icon={faPlus} className="mx-auto text-grey " />
          <label>{label}</label>
        </div>
      </Col>
      {showPanel && (
        <Col>
          {files.length > 0 && (
            <>
              <h6 className="mt-3">
                {files.length === 1
                  ? "File to be uploaded:"
                  : "Files to be uploaded:"}
              </h6>
              <Row>{files}</Row>
            </>
          )}
        </Col>
      )}
    </Row>
  );
}
export default MultipleFileUpload;
