import React from "react";
import BaseAgGridPage from "../../../../../components/BaseGrid";

const columns = [
  { headerName: "Species Name", field: "SpeciesName", filter: true, flex: 1 },
  { headerName: "EPPO Code", field: "EPPOCode", filter: true, flex: 1 },
  { headerName: "Species Code", field: "SpeciesCode", filter: true, flex: 1 },
];

function GridSpeciesTable() {
  return (
    <BaseAgGridPage
      title="Scientific species"
      description="This is a list of scientific species codes and names allowed within the Species field in I.28."
      dataUrl="https://ivettedstorage.blob.core.windows.net/product-images/SpeciesTable.json"
      headers={{ /* optional headers if needed */ }}
      path="" // If your JSON is directly an array, leave path empty
      columns={columns}
      height={500}
      width="100%"
    />
  );
}

export default GridSpeciesTable;