import React, { useState } from "react";
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import BreadcrumbNav from "../../components/BreadcrumbNav";
import PermissionsCheck from '../../auth/PermissionCheck';
import { roles } from '../../auth/PermissionsMap';
import Select from 'react-select';
import Flag from 'react-country-flag';

// Translations for multiple languages with video URLs
const translations = {
    en: {
        userGuides: "User Guides",
        navigationRegistration: "Navigation and Registration",
        nav: "Navigation around Dashboard - Manufacturer",
        navVideo: "https://www.youtube.com/embed/j7wK8v0H1Ws",
        regManu: "Registration - Manufacturer",
        regManuVideo: "https://www.youtube.com/embed/DoTY5URwyBo",
        regVet: "Registration - Registered Vet",
        regVetVideo: "https://www.youtube.com/embed/w8TSGrSG-IY",
        usingWebsite: "Using the website",
        choosingCertificateType: "Choosing a certificate type",
        step1: "Step 1 - Site Registration Via Health Mark",
        step1Video: "https://www.youtube.com/embed/-ogiYieCnno",
        step2: "Step 2 - Product Information",
        step2Video: "https://www.youtube.com/embed/KnpmeZiMRE8",
        step3: "Step 3 - Product Detail Information",
        step3Video: "https://www.youtube.com/embed/tTHwMolGifw",
        step4: "Step 4 - Composite Information",
        step4Video: "https://www.youtube.com/embed/pq8ZJN5PxQQ",
        step5Part1: "Step 5 (Part 1) - Request as Support Attestation",
        step5Part1Video: "https://www.youtube.com/embed/JicuqXm40Hg",
        step5Part2: "Step 5 (Part 2) - Request as Support Attestation",
        step5Part2Video: "https://www.youtube.com/embed/CwyXFgBeXew",
        step6: "Step 6 - Registered Vet Signing Support Attestation",
        step6Video: "https://www.youtube.com/embed/6O6VP21N7Hw",
        step7: "Step 7 - Reviewing the Signed Support Attestation",
        step7Video: "https://www.youtube.com/embed/DN4uxe7W9tU",
        step8: "Step 8 - Creating Daily Supplier Declarations",
        step8Video: "https://www.youtube.com/embed/BpzV3ZdTuXA",
        storageCertificate: "Storage Certificates (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/dHb3sz-rVio",
        groupage: "Groupage Export Facilitation Scheme (GEFS)",
        groupageVideo: "https://www.youtube.com/embed/aJeas26KZTg",
        nonGroupage: "Non-Groupage Export Facilitation (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/wmqfyV7DOEc"
    },
    es: {
        userGuides: "Guías del Usuario",
        navigationRegistration: "Navegación y Registro",
        usingWebsite: "Usando el sitio web",
        choosingCertificateType: "Eligiendo un tipo de certificado",
        step1: "Paso 1 - Registro en el Sitio",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Paso 2 - Información del Producto",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Paso 3 - Información Detallada del Producto",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Paso 4 - Información Compuesta",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Paso 5 (Parte 1) - Solicitar como Atestación de Soporte",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Paso 5 (Parte 2) - Solicitar como Atestación de Soporte",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Paso 6 - Veterinario Registrado Firmando Atestación de Soporte",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Paso 7 - Revisar la Atestación de Soporte Firmada",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Paso 8 - Creación de Declaraciones Diarias del Proveedor",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Certificados de Almacenamiento (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/aYjy7Tj_uQI ",
        groupage: "Esquema de Facilitación de Exportaciones en Grupo (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Facilitación de Exportaciones No Agrupadas (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
    fr: {
        userGuides: "Guides de l'utilisateur",
        navigationRegistration: "Navigation et Inscription",
        usingWebsite: "Utilisation du site web",
        choosingCertificateType: "Choisir un type de certificat",
        step1: "Étape 1 - Inscription au Site",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Étape 2 - Informations sur le Produit",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Étape 3 - Informations Détails Produit",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Étape 4 - Informations Composites",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Étape 5 (Partie 1) - Demande comme Attestation de Support",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Étape 5 (Partie 2) - Demande comme Attestation de Support",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Étape 6 - Vétérinaire Inscrit Signant l'Attestation de Support",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Étape 7 - Examiner l'Attestation Signée",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Étape 8 - Création de Déclarations Quotidiennes du Fournisseur",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Certificats de Stockage (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/OMipJh-nqp0",
        groupage: "Schéma de Facilitation des Exportations en Groupage (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Facilitation des Exportations Non Groupées (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
    de: {
        userGuides: "Benutzerhandbücher",
        navigationRegistration: "Navigation und Registrierung",
        usingWebsite: "Verwendung der Website",
        choosingCertificateType: "Auswahl des Zertifikatstyps",
        step1: "Schritt 1 - Seitenregistrierung",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Schritt 2 - Produktinformationen",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Schritt 3 - Produktdetailinformationen",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Schritt 4 - Zusammensetzende Informationen",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Schritt 5 (Teil 1) - Antrag als Unterstützungsbescheinigung",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Schritt 5 (Teil 2) - Antrag als Unterstützungsbescheinigung",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Schritt 6 - Registrierte Tierarztunterschrift Unterstützungsbescheinigung",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Schritt 7 - Überprüfung der unterschriebenen Unterstützungsbescheinigung",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Schritt 8 - Erstellung täglicher Lieferantenerklärungen",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Lagerzertifikate (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/Kob-iTfNoBY",
        groupage: "Sammelgut-Export-Erleichterungssystem (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Nicht-Sammelgut-Export-Erleichterung (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
    it: {
        userGuides: "Guide dell'utente",
        navigationRegistration: "Navigazione e registrazione",
        usingWebsite: "Utilizzo del sito web",
        choosingCertificateType: "Scelta del tipo di certificato",
        step1: "Passo 1 - Registrazione del sito",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Passo 2 - Informazioni sul prodotto",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Passo 3 - Informazioni dettagliate sul prodotto",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Passo 4 - Informazioni composite",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Passo 5 (Parte 1) - Richiesta come attestazione di supporto",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Passo 5 (Parte 2) - Richiesta come attestazione di supporto",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Passo 6 - Attestazione di supporto firmata dal veterinario registrato",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Passo 7 - Revisione dell'attestazione di supporto firmata",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Passo 8 - Creazione delle dichiarazioni giornaliere dei fornitori",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Certificati di stoccaggio (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/P3DRXJoRcU8",
        groupage: "Schema di Facilitazione all'Esportazione a Gruppo (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Facilitazione all'Esportazione Non a Gruppo (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
    nl: {
        userGuides: "Gebruikershandleidingen",
        navigationRegistration: "Navigatie en registratie",
        usingWebsite: "Gebruik van de website",
        choosingCertificateType: "Kiezen van het certificaattype",
        step1: "Stap 1 - Site registratie",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Stap 2 - Productinformatie",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Stap 3 - Gedetailleerde productinformatie",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Stap 4 - Samengestelde informatie",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Stap 5 (Deel 1) - Aanvraag als ondersteuningsattestatie",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Stap 5 (Deel 2) - Aanvraag als ondersteuningsattestatie",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Stap 6 - Ondersteuningsattestatie ondertekend door geregistreerde dierenarts",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Stap 7 - Herziening van de ondertekende ondersteuningsattestatie",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Stap 8 - Aanmaken van dagelijkse leveranciersverklaringen",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Opslagcertificaten (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/zeTDLRknSvY",
        groupage: "Groepage Export Facilitation Scheme (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Non-Groupage Export Facilitation (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
    pl: {
        userGuides: "Przewodniki użytkownika",
        navigationRegistration: "Nawigacja i rejestracja",
        usingWebsite: "Korzystanie ze strony internetowej",
        choosingCertificateType: "Wybór typu certyfikatu",
        step1: "Krok 1 - Rejestracja strony",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Krok 2 - Informacje o produkcie",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Krok 3 - Szczegółowe informacje o produkcie",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Krok 4 - Informacje złożone",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Krok 5 (Część 1) - Wniosek o potwierdzenie wsparcia",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Krok 5 (Część 2) - Wniosek o potwierdzenie wsparcia",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Krok 6 - Potwierdzenie wsparcia podpisane przez zarejestrowanego weterynarza",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Krok 7 - Przegląd podpisanego potwierdzenia wsparcia",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Krok 8 - Tworzenie codziennych deklaracji dostawców",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Certyfikaty magazynowe (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/zeTDLRknSvY",
        groupage: "Program Ułatwień Eksportowych Grupowych (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Ułatwienia Eksportowe Niegrupowe (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
    pt: {
        userGuides: "Guias do Usuário",
        navigationRegistration: "Navegação e Registro",
        usingWebsite: "Usando o site",
        choosingCertificateType: "Escolhendo o tipo de certificado",
        step1: "Passo 1 - Registro do Site",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Passo 2 - Informações do Produto",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Passo 3 - Informações Detalhadas do Produto",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Passo 4 - Informações Compostas",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Passo 5 (Parte 1) - Solicitação como Atestado de Suporte",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Passo 5 (Parte 2) - Solicitação como Atestado de Suporte",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Passo 6 - Atestado de Suporte Assinado pelo Veterinário Registrado",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Passo 7 - Revisão do Atestado de Suporte Assinado",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Passo 8 - Criação de Declarações Diárias de Fornecedores",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Certificados de Armazenamento (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/zeTDLRknSvY",
        groupage: "Esquema de Facilitação de Exportação em Grupo (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Facilitação de Exportação Não em Grupo (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
    cs: {
        userGuides: "Uživatelské Příručky",
        navigationRegistration: "Navigace a Registrace",
        usingWebsite: "Používání webu",
        choosingCertificateType: "Výběr typu certifikátu",
        step1: "Krok 1 - Registrace Stránky",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Krok 2 - Informace o Produktu",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Krok 3 - Podrobné Informace o Produktu",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Krok 4 - Složené Informace",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Krok 5 (Část 1) - Žádost jako Potvrzení Podpory",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Krok 5 (Část 2) - Žádost jako Potvrzení Podpory",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Krok 6 - Potvrzení Podpory Podepsané Registrovaným Veterinářem",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Krok 7 - Kontrola Podepsaného Potvrzení Podpory",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Krok 8 - Vytváření Denních Prohlášení Dodavatelů",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Skladovací Certifikáty (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/zeTDLRknSvY",
        groupage: "Schéma Ulehčení Exportu Skupin (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Ulehčení Exportu Neskupinového (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
    da: {
        userGuides: "Brugervejledninger",
        navigationRegistration: "Navigation og Registrering",
        usingWebsite: "Brug af hjemmesiden",
        choosingCertificateType: "Valg af certificattype",
        step1: "Trin 1 - Websted Registrering",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Trin 2 - Produktinformation",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Trin 3 - Detaljeret Produktinformation",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Trin 4 - Sammensat Information",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Trin 5 (Del 1) - Anmodning som Støtteattest",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Trin 5 (Del 2) - Anmodning som Støtteattest",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Trin 6 - Støtteattest Underskrevet af Registreret Dyrlæge",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Trin 7 - Gennemgang af Den Underskrevne Støtteattest",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Trin 8 - Oprettelse af Daglige Leverandørerklæringer",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Opbevaringscertifikater (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/zeTDLRknSvY",
        groupage: "Gruppage Eksportfremmende Ordning (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Ikke-Gruppage Eksportfremmende (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
    sv: {
        userGuides: "Användarguider",
        navigationRegistration: "Navigering och registrering",
        usingWebsite: "Använda webbplatsen",
        choosingCertificateType: "Välja certifikattyp",
        step1: "Steg 1 - Webbplatsregistrering",
        step1Video: "https://www.youtube.com/embed/zeTDLRknSvY",
        step2: "Steg 2 - Produktinformation",
        step2Video: "https://www.youtube.com/embed/bFqNfwtZGlk",
        step3: "Steg 3 - Detaljerad produktinformation",
        step3Video: "https://www.youtube.com/embed/hcJ7YlDpFLU",
        step4: "Steg 4 - Sammanställd information",
        step4Video: "https://www.youtube.com/embed/OvyqKmGCv34",
        step5Part1: "Steg 5 (Del 1) - Begäran som stödintyg",
        step5Part1Video: "https://www.youtube.com/embed/7R2dhd7QJX0",
        step5Part2: "Steg 5 (Del 2) - Begäran som stödintyg",
        step5Part2Video: "https://www.youtube.com/embed/PgHHPqZcemY",
        step6: "Steg 6 - Stödintyg undertecknat av registrerad veterinär",
        step6Video: "https://www.youtube.com/embed/kpd9UycdRnY",
        step7: "Steg 7 - Granskning av det undertecknade stödintyget",
        step7Video: "https://www.youtube.com/embed/YSdRg6LcHIU",
        step8: "Steg 8 - Skapande av dagliga leverantörsdeklarationer",
        step8Video: "https://www.youtube.com/embed/WFCDziAa2_w",
        storageCertificate: "Lagringscertifikat (EHC 8461)",
        storageCertificateVideo: "https://www.youtube.com/embed/qjryO5aP6v0",
        groupage: "Gruppage Export Facilitation Scheme (GEFS) ",
        groupageVideo: "https://www.youtube.com/embed/bFqNfwtZGlk",
        nonGroupage: "Icke-Gruppage Export Facilitation (Non-GEFS)",
        nonGroupageVideo: "https://www.youtube.com/embed/hcJ7YlDpFLU"
    },
};


const languageOptions = [
    { value: 'en', label: 'English', flag: 'GB' },
    { value: 'es', label: 'Español', flag: 'ES' },
    { value: 'fr', label: 'Français', flag: 'FR' },
    { value: 'de', label: 'Deutsch', flag: 'DE' },
    { value: 'it', label: 'Italiano', flag: 'IT' },
    { value: 'sv', label: 'Svenska', flag: 'SE' },
    // { value: 'nl', label: 'Nederlands', flag: 'NL' },
    // { value: 'pl', label: 'Polski', flag: 'PL' },
    // { value: 'pt', label: 'Português', flag: 'PT' },
    // { value: 'cs', label: 'Čeština', flag: 'CZ' },
    // { value: 'da', label: 'Dansk', flag: 'DK' },
    // { value: 'hu', label: 'Magyar', flag: 'HU' },
    // { value: 'el', label: 'Ελληνικά', flag: 'GR' },
    // { value: 'bg', label: 'Български', flag: 'BG' },
    // { value: 'hr', label: 'Hrvatski', flag: 'HR' },
    // { value: 'et', label: 'Eesti', flag: 'EE' },
    // { value: 'fi', label: 'Suomi', flag: 'FI' },
    // { value: 'lv', label: 'Latviešu', flag: 'LV' },
    // { value: 'lt', label: 'Lietuvių', flag: 'LT' },
    // { value: 'mt', label: 'Malti', flag: 'MT' },
    // { value: 'ro', label: 'Română', flag: 'RO' },
    // { value: 'sk', label: 'Slovenčina', flag: 'SK' },
    // { value: 'sl', label: 'Slovenščina', flag: 'SI' },
    // { value: 'ga', label: 'Gaeilge', flag: 'IE' },
];

function UserGuides(props) {
    const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);

    const manufacturerGuides = [roles.admin, roles.retailer, roles.manufacturer, roles.manufactureradmin];
    const vetGuides = [roles.admin, roles.retailer, roles.vet, roles.certifyingofficer];

    const handleLanguageChange = (option) => {
        setSelectedLanguage(option);
    };

    const text = translations[selectedLanguage.value] || translations['en']; // Fallback to English if translation missing

    // Determine if the selected language is English
    const isEnglish = selectedLanguage.value === 'en';

    return (
        <>
            <BreadcrumbNav
                data={{
                    item1: { name: text.userGuides, path: '/' },
                    item2: { name: text.userGuides, path: '/' },
                }}
            />

            {/* Positioning the dropdown at the top-right corner */}
            <Row className="align-items-center">
                <Col>
                    <h2 className="mb-3" id="tabelLabel">{text.userGuides}</h2>
                </Col>
                <Col className="language-toggle-section">
                    <Select
                        className="language-dropdown"
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        options={languageOptions.map(option => ({
                            value: option.value,
                            label: (
                                <div className="language-option">
                                    <Flag countryCode={option.flag} svg style={{ marginRight: 10 }} />
                                    {option.label}
                                </div>
                            )
                        }))}
                        isSearchable
                    />
                    {/* Place the toggle button here if needed */}
                    <div className="toggle-button">
                        {/* Your toggle button goes here */}
                    </div>
                </Col>
            </Row>

            {/* Conditional Rendering Based on Language */}
            {isEnglish ? (
                <>
                    <h4 className="my-3 text-title">{text.navigationRegistration}</h4>
                    <Row>
                        <Col xl={12}>
                            <Row>
                                <PermissionsCheck errorProps={{ disabled: true }} requiresRole={manufacturerGuides}>
                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.nav}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.navVideo}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.regManu}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.regManuVideo}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    </PermissionsCheck>

                                    <PermissionsCheck errorProps={{ disabled: true }} requiresRole={vetGuides}>

                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.regVet}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.regVetVideo}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    </PermissionsCheck>

                                <h4 className="my-3 text-title">{text.usingWebsite}</h4>

                                <PermissionsCheck errorProps={{ disabled: true }} requiresRole={manufacturerGuides}>
                                <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.step1}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.step1Video}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.step2}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.step2Video}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.step3}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.step3Video}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.step4}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.step4Video}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.step5Part1}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.step5Part1Video}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.step5Part2}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.step5Part2Video}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </PermissionsCheck>

                                <PermissionsCheck errorProps={{ disabled: true }} requiresRole={vetGuides}>
                                    <>
                                        <Col lg={6} xl={4}>
                                            <Card border="light" className="bg-white shadow-sm mb-4">
                                                <Card.Header>
                                                    <Row className="align-items-center">
                                                        <Col>
                                                            <label className="mb-0">{text.step6}</label>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <iframe
                                                        width="100%"
                                                        height="200px"
                                                        src={text.step6Video}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    ></iframe>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={6} xl={4}>
                                            <Card border="light" className="bg-white shadow-sm mb-4">
                                                <Card.Header>
                                                    <Row className="align-items-center">
                                                        <Col>
                                                            <label className="mb-0">{text.step7}</label>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <iframe
                                                        width="100%"
                                                        height="200px"
                                                        src={text.step7Video}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    ></iframe>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </>
                                </PermissionsCheck>

                                <PermissionsCheck errorProps={{ disabled: true }} requiresRole={manufacturerGuides}>
                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.step8}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.step8Video}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </PermissionsCheck>


                                <PermissionsCheck errorProps={{ disabled: true }} requiresRole={manufacturerGuides}>
                                <h4 className="my-3 text-title">{text.choosingCertificateType}</h4>
                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.storageCertificate}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.storageCertificateVideo}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.groupage}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.groupageVideo}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col lg={6} xl={4}>
                                        <Card border="light" className="bg-white shadow-sm mb-4">
                                            <Card.Header>
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <label className="mb-0">{text.nonGroupage}</label>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <iframe
                                                    width="100%"
                                                    height="200px"
                                                    src={text.nonGroupageVideo}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </PermissionsCheck>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <h4 className="my-3 text-title">{text.choosingCertificateType}</h4>
                    <Row>
                        <PermissionsCheck errorProps={{ disabled: true }} requiresRole={manufacturerGuides}>
                            <Col lg={6} xl={4}>
                                <Card border="light" className="bg-white shadow-sm mb-4">
                                    <Card.Header>
                                        <Row className="align-items-center">
                                            <Col>
                                                <label className="mb-0">{text.storageCertificate}</label>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <iframe
                                            width="100%"
                                            height="200px"
                                            src={text.storageCertificateVideo}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </PermissionsCheck>
                    </Row>
                </>
            )}
        </>
    );
}

export default UserGuides;
