import React from "react";
import GridSpeciesTable from "./components/GridSpeciesTable";
import GridBseRegions from "./components/GridBseRegions";
import GridTreatmentTable from "./components/GridTreatmentTable";
import GridTrichinellaTable from "./components/GridTrichinellaTable";
import { Col, Row, Tabs, Tab } from "react-bootstrap";
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Routes } from "../../../../routes";
import { Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faChevronLeft, faTools } from "@fortawesome/free-solid-svg-icons";


function CertificateToolsPage() {
  const breadcrumbData = {
    item1: {
      name: "Tools",
      link: Routes.ToolsDashboardPage.path,
    },
    item2: {
      name: "Certificate Tools",
      link: null,
    },
  };
  
  return (
    <>
      <BreadcrumbNav data={breadcrumbData} />
      <Row className="row d-flex align-items-start">
        <Col xs={12} sm={6} lg={6} className="text-start mt-4">
          <h2>Certificate Tools</h2>
        </Col>
        <Col xs={12} sm={6} lg={6} className="text-end mt-4">
          <Button
            variant="light"
            className="mx-3"
            onClick={() => {
              window.open(Routes.CertificateToolsPage.path, "_self");
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
            Back to Certificate Verifier
          </Button>
        </Col>
        <Col xs={12} className="text-start">
          <Tabs
          variant="pills"
            defaultActiveKey="treatment"
            id="uncontrolled-tab-example"
            className="mb-3 mt-5"
          >
            <Tab eventKey="treatment" title="Treatments permitted">
              <GridTreatmentTable />
            </Tab>
            <Tab eventKey="trichinella" title="Trichinella regions">
              <GridTrichinellaTable />
            </Tab>
            <Tab eventKey="species" title="Scientific species">
              <GridSpeciesTable />
            </Tab>
            <Tab eventKey="bse" title="BSE regions">
              <GridBseRegions />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
}

export default CertificateToolsPage;
