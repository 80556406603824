import React, { useState, useEffect } from "react";
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Routes } from "../../../../routes";
import { Row, Col, Card, Button, Badge } from "@themesberg/react-bootstrap";
import PermissionsCheck from "../../../../auth/PermissionCheck";
import { scopes } from "../../../../auth/PermissionsMap";
import CertificateCheckerService from "../../../services/CertificateCheckerService";
import UploadCertificatePanel from "./components/UploadCertificatePanel";
import CertificateChecklist from "./components/CertificateChecklist";
import { formatBytes, formatFileName } from "../../../../utils/formatFiles";
import { v4 as uuid } from "uuid";
import FormScripts from "../../../../components/FormScripts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { faList, faTools } from "@fortawesome/free-solid-svg-icons";

const CertificateCheckerPage = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [checkResponses, setCheckResponses] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [errors, setErrors] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Standard message for beforeunload events
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome
    };

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const breadcrumbData = {
    item1: {
      name: "Tools",
      link: Routes.ToolsDashboardPage.path,
    },
    item2: {
      name: "Certificate Checker",
      link: null,
    },
  };

  const handleFilesAdded = (files) => {
    var newFiles = [];
    files.map((file) => {
      var fileSize = formatBytes(file.size, 0);
      var fileName = formatFileName(file.path);
      newFiles.push({
        id: uuid(),
        fullFile: file,
        file: file.path,
        size: fileSize,
        version: 0.0,
        formName: "",
        filename: fileName,
        selected: true,
        isExtracting: false,
        isVerifying: false,
        isRunningRules: false,
        isAnalysisComplete: false,
        isCompleted: false,
        isError: false,
        passed: false,
        errors: [],
      });
    });
    setChecklist((prev) => [...prev, ...newFiles]);
  };

  return (
    <div>
      <BreadcrumbNav data={breadcrumbData} />
      <Row className="row d-flex align-items-center">
        <Col xs={12} sm={6} lg={6} className="text-start my-4">
          <h2>Certificate Verifier</h2>
        </Col>
        <Col xs={12} sm={6} lg={6} className="text-end">
          <Button
            variant="light"
            className="mx-3"
            onClick={() => {
              window.open(Routes.CertificateToolsPage.path, "_self");
            }}
          >
            <FontAwesomeIcon icon={faTools} className="me-2" />
            Tools
          </Button>
          <Button
            variant="primary"
            className=""
            onClick={() => {
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" />
            Start again
          </Button>
        </Col>
      </Row>
      <Row className="g-4">{renderCards()}</Row>
    </div>
  );

  async function onAnalysis(values, setFieldValue) {
    setSubmitting(true);
  
    // Clone the items array to avoid direct mutation
    const updatedItems = values.items
      .filter((x) => !x.isAnalysisComplete)
      .map((item) => ({ ...item }));
  
    try {
      for (const item of updatedItems) {
        if (!item.selected) continue;
  
        // Set initial extracting state
        item.isExtracting = true;
        item.isAnalysisComplete = false;
        setFieldValue("items", [...updatedItems]);
  
        const formData = new FormData();
        formData.append("file", item.fullFile);
  
        try {
          // Step 1: Extract Data
          const extractedData = await CertificateCheckerService.extractData(formData);
          console.info("Extracted data", extractedData?.data);
  
          if (extractedData.errors?.length > 0) {
            throw new Error("Error during data extraction");
          }
  
  
          var reference = extractedData.data?.formNumberVersion;
          console.info("Extracted reference", reference);
          var model = JSON.stringify(extractedData.data);

          item.isExtracting = false;
          item.isVerifying = true;
          item.isRunningRules = false;
          setFieldValue("items", [...updatedItems]);


          // Step 2: Check restriction zones
          var updatedData = await CertificateCheckerService.checkASFRestrictionZones(model, reference);
          console.info("Restriction zone", updatedData.data);
          var updatedModel = JSON.stringify(updatedData.data);
          setFieldValue("items", [...updatedItems]);

          // Step 3: Verify Data
          const verificationResult = await CertificateCheckerService.verifyData(updatedModel, reference);
          console.info("Verification result", verificationResult);
  
          if (verificationResult.data?.errors?.length > 0) {
            item.passed = false;
            item.errors = verificationResult.data.errors;
            item.successMessages = verificationResult.data.successMessages;
            setFieldValue("items", [...updatedItems]);
            console.info("Verification result errors", verificationResult.data.errors);
          } else {
            item.passed = true;
            item.data = verificationResult?.data;
            item.successMessages = verificationResult.data.successMessages;
            setFieldValue("items", [...updatedItems]);
          }
  
          // Mark analysis as complete
          item.isExtracting = false;
          item.isVerifying = false;
          item.isRunningRules = false;
          item.isAnalysisComplete = true;
          setFieldValue("items", [...updatedItems]);
        } catch (serviceError) {
          // Handle service-specific errors
          console.error(serviceError.message, serviceError);
          item.isError = true;
          item.isExtracting = false;
          item.isRunningRules = false;
          item.isVerifying = false;
          item.isAnalysisComplete = true;
          item.errors = [serviceError.message || "An error occurred"];
          setFieldValue("items", [...updatedItems]);
        }
  
      }
    } catch (globalError) {
      console.error("An unexpected error occurred", globalError);
    } finally {
      setSubmitting(false);
    }
  }
  
  function renderCards() {
    return (
      <PermissionsCheck
        errorProps={{ disabled: true }}
        scopes={[scopes.tools.certificatechecker]}
      >
        <Col xs={12} lg={8} className="my-4">
          <FormScripts />
          <CertificateChecklist
            checkResponses={checkResponses}
            isSubmitting={isSubmitting}
            onAnalysis={onAnalysis}
            checklist={checklist}
            setChecklist={setChecklist}
          />
        </Col>
        <Col xs={12} lg={4} className="my-4">
          <UploadCertificatePanel
            isSubmitting={isSubmitting}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            onAdded={handleFilesAdded}
          />
        </Col>
      </PermissionsCheck>
    );
  }
};
export default CertificateCheckerPage;
