import *  as baseService  from "./BaseService";

const downloadBlobAsJson = (path) => {
    return baseService.getApiData("/FileDownload/DownloadBlobAsJson?path=" + path);
};

const FileService = {
    downloadBlobAsJson
};

export default FileService;