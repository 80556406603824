import * as baseService from "./BaseService";

const extractData = (file) => {
  return baseService.postApiData("/CertificateChecker/ExtractData", file);
};
const checkASFRestrictionZones = (data, reference) => {
  const formData = new FormData();
  formData.append("data", data);
  formData.append("reference", reference);
  return baseService.postApiData(
    "/CertificateChecker/CheckASFRestrictionZones",
    formData
  );
};
const verifyData = (data, reference) => {
  const formData = new FormData();
  formData.append("data", data);
  formData.append("reference", reference);
  return baseService.postApiData(
    "/CertificateChecker/verifyData",
    formData
  );
};

const CertificateCheckerService = {
  extractData,
  checkASFRestrictionZones,
  verifyData,
};

export default CertificateCheckerService;
