import React from "react";
import BaseAgGridPage from "../../../../../components/BaseGrid";

const columns = [
  { headerName: "Region Name", field: "regionCode", filter: true, flex: 1 },
  { headerName: "Species Code", field: "speciesCode", filter: true, flex: 1 },
  { headerName: "Treatment Code", field: "treatmentCode", filter: true, flex: 1 },
];

function GridTreatmentTable() {
  return (
    <BaseAgGridPage
      title="Treatments permitted"
      description="This is a list of permitted treatments for species codes within regions."
      dataUrl="https://ivettedstorage.blob.core.windows.net/product-images/TreatmentTable.json"
      headers={{ /* optional headers if needed */ }}
      path="" // If your JSON is directly an array, leave path empty
      columns={columns}
      height={500}
      width="100%"
    />
  );
}

export default GridTreatmentTable;