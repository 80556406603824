
  export default {
    formId: 'storageDeclarationForm',
    formField: {
      formsComplete: {
        name: 'formsComplete',
        label: 'Forms Completed',
        requiredErrorMsg: 'forms are required'
      },
      signedRequestUrl: {
        name: 'signedRequestUrl',
        label: 'Signed Request Url',
        requiredErrorMsg: ''
      },
      documentId: {
        name: 'documentId',
        label: 'Document ID',
        requiredErrorMsg: ''
      },
      finalConsumer: {
        name: 'finalConsumer',
        label: 'Final consumer',
        requiredErrorMsg: 'Final consumer is required'
      },
      batches: {
        name: 'batches',
        label: 'Batches:',
        requiredErrorMsg: 'Batch numbers is required'
      },
      netWeight: {
        name: 'netWeight',
        label: 'Net weight (kg):',
        requiredErrorMsg: 'Net weight is required'
      },
      grossWeight: {
        name: 'grossWeight',
        label: 'Gross weight (kg):',
        requiredErrorMsg: 'Gross weight is required'
      },
      totalNetWeight: {
        name: 'totalNetWeight',
        label: 'Total Net weight',
        requiredErrorMsg: 'Total Net weight is required'
      },
      totalGrossWeight: {
        name: 'totalGrossWeight',
        label: 'Total Gross weight',
        requiredErrorMsg: 'Total Gross weight is required'
      },
      invoiceNumber: {
        name: 'invoiceNumber',
        label: 'Invoice / PO number:',
        requiredErrorMsg: 'Invoice / PO number is required'
      },
      numberPackages: {
        name: 'numberPackages',
        label: 'Number of packages: ',
        requiredErrorMsg: 'Number of packages is required'
      },
      weightType: {
        name: 'weightType',
        label: 'Weight Type: ',
        requiredErrorMsg: 'Weight Type is required'
      }
    }
  };
  
