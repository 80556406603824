import { faCheck, faEllipsisH, faFileContract, faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Col, Dropdown, Row, Table } from '@themesberg/react-bootstrap';
import { Form, Formik } from 'formik';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import PermissionsCheck from '../../../auth/PermissionCheck';
import FormController from "../../../pages/components/common/FormController";
import Paging from "../../../pages/components/common/Paging";
import AttestationService from "../../../pages/services/AttestationService";
import BlobService from "../../../pages/services/BlobService";
import ManufacturerService from "../../../pages/services/ManufacturerService";
import { Routes } from "../../../routes";
import Preloader from "../../Preloader";
import { NoResults } from "../NoResults";
  

export const AttestationNonGEFSTable = (props) => {

  const [selected, setSelected] = useState(false); 

  const [count, setCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const {
    setFormChanged = false,
    contentsSelected,
    totalPages = count,
    size = "md", 
    withIcons = false, 
    isSelectForm = true, 
    onChange, 
  } = props;

  const [attestations, setAttestations] = useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [nonGEFS] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [manufacturers, setManufacturers] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const history = useHistory();

  const initialValues = {
    searchTerms: '',
    manufacturerId:'',
    deliveryDate:'',
    showNonGEFS:true,
  }

  const [filterValues, setFilterValues] = useState(initialValues);

  function onSubmit(values, { setSubmitting, resetForm } ) {
    setTimeout(() => {
      setActivePage(1);
      setFilterValues(values);
      retrieveAttestations(values);
      console.log(JSON.stringify(values, null, 2));
      setSubmitting(false);
    }, 1000);
  }

  useEffect(() => {
    if(isSelectForm){
      //alert("contentsSelected: " + formValues.contentsSelected);
    }
    getManufacturerData();
  },[isSelectForm]);
  
  async function getManufacturerData(){
    ManufacturerService.getAll()
      .then((response) => {
          const options = response.data.map(d => ({
              "value": d.id,
              "label": d.manufacturerName
          })).sort((a, b) => a.label.localeCompare(b.label));
          setManufacturers(options);
      })
      .catch((e) => {
          console.log(e);
      });
  }

  const retrieveAttestations = (values = null) => {
    let params = '';
    const currentValues = values || filterValues;
    params = getFilterParams(currentValues.searchTerms, currentValues.manufacturerId, currentValues.deliveryDate, activePage, pageSize);
    getAttestationsData(params);
  };

  useEffect(() => {
      retrieveAttestations();
  },[activePage, pageSize]);

  useEffect(() => {
    if(contentsSelected != []){
      setFormChanged(true);
    }
    else{
      setFormChanged(false);
    }
  },[contentsSelected]);

  const getFilterParams = (searchTerms, manufacturerId, deliveryDate, activePage, pageSize) => {
    let params = {};
    if (searchTerms) {
        params["searchTerms"] = searchTerms;
    }
    if (manufacturerId) {
        params["manufacturerId"] = manufacturerId;
    }
    if (activePage) {
        params["page"] = activePage;
    }
    if (pageSize) {
        params["size"] = pageSize;
    }
    return params;
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value.value);
    setActivePage(1);
  };

  const openBlobPdf = (filename) => {
    BlobService.openBlobPdf(filename);
  }

  const getAttestationsData = (params) => {
    setLoaded(false);
    AttestationService.getAllPagedNonGEFS(params)
        .then((response) => {
            const { data, totalPages, totalRecords} = response.data;
            setTotalItems(totalRecords);
            setAttestations(data);
            setCount(totalPages);
            setLoaded(true);
        })
        .catch((e) => {
            setLoaded(true);
            console.log(e);
        });
  };
  

  //Table Row
  const TableRow = (props) => {
    const {
      id, 
      attestationRequest, 
      attestationDetails, 
      productName = JSON.parse(attestationDetails.productJson).product?.productName, 
      manufacturer,
      dateCollectionProduction = attestationDetails?.dateCollectionProduction,  
      uniqueCode = attestationDetails?.uniqueCode, 
      batchNumbers = batchNumbers,
      manufacturerName = manufacturer?.manufacturerName, 
      attestationResponse, 
      signedDocument =  attestationResponse?.signedDocument, 
      certificateNumberList,
      isCompound =  certificateNumberList?.length>1 ? true : false, 
      validFrom, 
      validTo, 
      selections
    } = props;
    const [selected, setSelected] = useState(false);

    useEffect(() => {
      if(isSelected()){
        setSelected(true)
      }
      else{
        setSelected(false)
      }
    }, []);

    const isSelected = () => {
      if(selections!==undefined){
        const found = selections.find(x => x.id === id );
        if(found!== undefined && found !== null){
          return true;
        }
      }
      return false;
    }

    const getSADate = (date) => {
      var newDate = moment(date).format("DD/MM/YY");
      if (newDate === "Invalid date") {
          return "";
      }
      return newDate;
    }

    const selectItem = (id, productName, uniqueCode, manufacturerName, signedDocument, batchNumbers, validTo, dateCollectionProduction) => { 
      var res = contentsSelected;
      var item = {
        id: id, 
        isCompound: isCompound, 
        productName: productName,
        dailyAttestationId: id, 
        certificateNumbers: props.certificateNumberList,
        commodityCode: JSON.parse(attestationDetails.productJson)?.product?.productDetails?.commodityHscode,
        netWeightTotal: (props.attestationResponse?.attestationResponseNonGEFS?.numberProductsCases * JSON.parse(attestationDetails.productJson)?.product?.productDetails?.netWeight).toFixed(3),
        grossWeightTotal: (props.attestationResponse?.attestationResponseNonGEFS?.numberProductsCases * JSON.parse(attestationDetails.productJson)?.product?.productDetails?.grossWeight).toFixed(3),
        numberOfPackages: props.attestationResponse?.attestationResponseNonGEFS?.numberProductsCases,
        uniqueCode: uniqueCode, 
        batchNumbers: batchNumbers,
        manufacturerName: manufacturerName, 
        deliveryDate: validTo, 
        dailyAttestationItems: null, 
        productJson: attestationDetails.productJson,
        isGEFS: false,
        attestation: props,
        signedDocument: signedDocument, 
        dateCollectionProduction: dateCollectionProduction,
        combinations: [],
        consignmentContentsId: uuid()
      };

      if(!selected)
        {
          setSelected(true);
          setFormChanged(true);
          var hasMatch = contentsSelected.find(x=>x.attestation?.id === item.attestation?.id);
          if(hasMatch === undefined){
            res.push(item);
          }
        }
        else{
          res.pop(item);
          setSelected(false);
          setFormChanged(true);
        }
    }

    return (
      <tr className={selected ? "bg-lightblue" : null}>
        <td id={id}>
              {uniqueCode}
        </td>
        <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
          <td id={id}>
          <span className="fw-normal">
            {manufacturerName}
          </span>
          </td>
        </PermissionsCheck>
        <td id={id} >
          <span className="fw-normal">
            {batchNumbers}
          </span>
        </td>
        <td id={id} >
          <span className="fw-normal">
            {getSADate(validTo)}
          </span>
        </td>   
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="position-absolute">
              <Dropdown.Item onClick={()=>{openBlobPdf(signedDocument?.filename)}}>
                <FontAwesomeIcon icon={faFileContract} className="dropdown-item-icon" />
                Signed Declaration
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        {isSelectForm &&
          <td className="text-right px-4" >
            {selected?
            (
                <Button variant="primary" size="sm" className="text-center" 
                  onClick={() => selectItem(id, productName, uniqueCode,manufacturerName, signedDocument,batchNumbers, validTo,dateCollectionProduction)}>                
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              )
              :
              (
                <Button variant="light" size="sm" className="text-center"  
                  onClick={() => selectItem(id, productName, uniqueCode,manufacturerName, signedDocument,batchNumbers, validTo,dateCollectionProduction)}>
                  <FontAwesomeIcon icon={faPlus}/>
                </Button>
              )
            }
          </td>
        }
      </tr>
    );
  };

  //Start - Paging Functions
  const onPrevItem = () => {
    const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
    setActivePage(prevActiveItem);
  };

  const onPageNumberClick = (number) => {
    setActivePage(number);
  };
  const onNextItem = () => {
    const nextActiveItem = activePage === count ? activePage : activePage + 1;
    setActivePage(nextActiveItem);
  };
  //End - Paging Functions


  return (
    <>
      <Col xs={12} sm={6} lg={12}>
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            >
            {(props, isSubmitting) => (
              <Form>     
                  <Row className="mb-3">
                      <Col xs={12} sm={8} lg={4}>
                          <FormController
                              control="input"
                              type="text"
                              placeholder="Search by reference or unique document number"
                              name="searchTerms"
                          />
                      </Col>
                      
                      <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
                          <Col xs={12} sm={8} lg={4}>
                            <FormController
                                id="manufacturer"
                                name="manufacturer"
                                placeholder="Select a manufacturer"
                                control="multiselect"
                                options={manufacturers}
                                isMulti={false}
                                isClearable={true}
                                onBlur={props.setFieldTouched}
                                onChange={(name, value) => {
                                  props.setFieldValue('manufacturer',value);
                                  props.setFieldValue('manufacturerId',value?.value);
                                    
                                }}
                                touched={props.touched.manufacturer}
                                errors={props.errors.manufacturer}
                                isrequired="true"
                                col={12}
                            />
                          </Col>
                      </PermissionsCheck>    
                      
                      <Col xs={12} sm={2} lg={2} >
                          <div className="form-group">
                              <Button variant="light" type="submit" disabled={isSubmitting}>
                              <FontAwesomeIcon icon={faFilter} />{isSubmitting ? "Please wait..." : "Filter"}</Button>
                          </div>
                      </Col>
                  </Row>
              </Form>
            )}
        </Formik >
      </Col>
      <Preloader component={true} show={!loaded} />

      <Table hidden={!loaded || attestations.length === 0} responsive hover className="user-table align-items-center">
        <thead>
          <tr>
            <th className="border-bottom">Unique code</th>
            <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
              <th className="border-bottom">Manufacturer</th>
            </PermissionsCheck>
            <th className="border-bottom">Batch Numbers</th>
            <th className="border-bottom">Valid to</th>
            <th className="border-bottom"></th>
          </tr>
        </thead>
        <tbody>
            {attestations?.map(t => 
              <TableRow 
                  key={`attestation-${t.id}`}
                  selections={contentsSelected}
                  {...t} 
              />)}
        </tbody>
      </Table>

      <Paging
        activePage={activePage}
        size={size}
        pageSize={pageSize}
        totalPages={count}
        totalItems={totalItems}
        handleChange={handlePageSizeChange}
        onPrevItem={onPrevItem}
        onNextItem={onNextItem}
        onPageNumberClick={onPageNumberClick}
        withIcons={withIcons}
      />
      {loaded && attestations.length === 0 &&(
        <NoResults name="daily attestations"/>
      )}
    </>
  );
};

export default AttestationNonGEFSTable;