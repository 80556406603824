import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, useFormikContext } from 'formik';
import FormController from "../../common/FormController"
import ZoneService from "../../../services/ZoneService";
import ProductDairyService from "../../../services/ProductDairyService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as Dairy8468Labels from "./Dairy8468Labels"
import FormSubmission from "../../common/FormSubmission";
import { Button, Card, Alert, Col, Row } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import ColdStoreSelect from '../../../../components/EstablishmentSelect/ColdStoreSelect';
import SiteSelect from '../../../../components/EstablishmentSelect/SiteSelect';
import DairyEstablishmentSelect from '../../../../components/EstablishmentSelect/DairyEstablishmentSelect';
import RewrappingEstablishment from '../../../../components/EstablishmentSelect/RewrappingEstablishment';
import EstablishmentsService from '../../../services/EstablishmentsService';
import SiteService from '../../../services/SiteService';
import ActivityService from '../../../services/ActivityService';

const Dairy8468AddPage = (props) => {
    const certificateNumber = 8468;
    const [isSubmitting, setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [editing, setEditing] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId, setProductId] = useState(props.match.params.id);
    const [ready, setReady] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [memberStates, SetMemberStates] = useState([]);


    let title = (editing == true ? "Edit " : "New") + " Dairy (#8468) Information"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Dairy 8468', link: Routes.Dairy8468AddPage.path },
    };
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [zones, SetZones] = useState([]);
    const [apiValues, SetApiValues] = useState([]);

    const initialValues = {
        productId: props.match.params.id,
        coldStoreEstablishments: apiValues?.coldStoreEstablishments ?? [],
        identificationMarkEstablishments: apiValues?.identificationMarkEstablishments ?? siteEstablishment[0],
        rewrappingEstablishments: apiValues?.rewrappingEstablishments ?? [],
        coldStore: apiValues?.coldStore ?? '',
        identificationMark: apiValues?.identificationMark ?? '',
        dairySpecies1: apiValues?.dairySpecies1 ?? false,
        dairySpecies2: apiValues?.dairySpecies2 ?? false,
        dairySpecies3: apiValues?.dairySpecies3 ?? false,
        dairySpecies4: apiValues?.dairySpecies4 ?? false,
        dairySpecies5: apiValues?.dairySpecies5 ?? false,
        dairyACountryOrigin: apiValues?.dairyACountryOrigin ?? [],
        dairyBOriginatesIn: apiValues?.dairyBOriginatesIn ?? [],
        dairyB2OriginatesIn: apiValues?.dairyB2OriginatesIn ?? [],
        dairyBCountryOrigin: apiValues?.dairyBCountryOrigin ?? [],
        dairyB2CountryOrigin: apiValues?.dairyB2CountryOrigin ?? [],
        dairyP1: apiValues?.dairyP1 ?? false,
        dairyP2: apiValues?.dairyP2 ?? false,
        dairyProduct1: apiValues?.dairyProduct1 ?? false,
        dairyProduct2: apiValues?.dairyProduct2 ?? false,
        dairyProduct3: apiValues?.dairyProduct3 ?? false,
        dairyProductCountryOrigin: apiValues?.dairyProductCountryOrigin ?? [],
        dairyB_1: apiValues?.dairyB_1 ?? false,
        dairyB_2: apiValues?.dairyB_2 ?? false,
        dairyB_3: apiValues?.dairyB_3 ?? false,
        dairyB2_1: apiValues?.dairyB2_1 ?? false,
        dairyB2_2: apiValues?.dairyB2_2 ?? false,
        dairyB2_3: apiValues?.dairyB2_3 ?? false,
        dairyCMilkType1: apiValues?.dairyCMilkType1 ?? false,
        dairyCMilkType2: apiValues?.dairyCMilkType2 ?? false,
        dairyCMilkType3: apiValues?.dairyCMilkType3 ?? false,
        dairyCMilkType4: apiValues?.dairyCMilkType4 ?? false,
        dairyCMilkType5: apiValues?.dairyCMilkType5 ?? false,
        dairyC2MilkType1: apiValues?.dairyC2MilkType1 ?? false,
        dairyC2MilkType2: apiValues?.dairyC2MilkType2 ?? false,
        dairyC2MilkType3: apiValues?.dairyC2MilkType3 ?? false,
        dairyC2MilkType4: apiValues?.dairyC2MilkType4 ?? false,
        dairyC2MilkType5: apiValues?.dairyC2MilkType5 ?? false,
        dairyCStatements: apiValues?.dairyCStatements ?? [],
        dairyCStatements2: apiValues?.dairyCStatements2 ?? [],
        dairyC2Statements: apiValues?.dairyC2Statements ?? [],
        dairyC2Statements2: apiValues?.dairyC2Statements2 ?? [],
    }

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";

    //Validation
    const validationSchema = Yup.object().shape({
        identificationMarkEstablishments: Yup.object().nullable().required(validationMsgRequired),
        dairySpecies1: Yup.bool()
            .test('dairySpecies1', validationMsgAtLeastOne, function (x) {
                return (!this.parent.dairySpecies1 && !this.parent.dairySpecies2 && !this.parent.dairySpecies3 && !this.parent.dairySpecies4 && !this.parent.dairySpecies5)
                    ? false : true;
            }),
        dairySpecies2: Yup.bool()
            .test('dairySpecies2', validationMsgAtLeastOne, function (x) {
                return (!this.parent.dairySpecies1 && !this.parent.dairySpecies2 && !this.parent.dairySpecies3 && !this.parent.dairySpecies4 && !this.parent.dairySpecies5)
                    ? false : true;
            }),
        dairySpecies3: Yup.bool()
            .test('dairySpecies3', validationMsgAtLeastOne, function (x) {
                return (!this.parent.dairySpecies1 && !this.parent.dairySpecies2 && !this.parent.dairySpecies3 && !this.parent.dairySpecies4 && !this.parent.dairySpecies5)
                    ? false : true;
            }),
        dairySpecies4: Yup.bool()
            .test('dairySpecies4', validationMsgAtLeastOne, function (x) {
                return (!this.parent.dairySpecies1 && !this.parent.dairySpecies2 && !this.parent.dairySpecies3 && !this.parent.dairySpecies4 && !this.parent.dairySpecies5)
                    ? false : true;
            }),
        dairySpecies5: Yup.bool()
            .test('dairySpecies5', validationMsgAtLeastOne, function (x) {
                return (!this.parent.dairySpecies1 && !this.parent.dairySpecies2 && !this.parent.dairySpecies3 && !this.parent.dairySpecies4 && !this.parent.dairySpecies5)
                    ? false : true;
            }),
        dairyACountryOrigin: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        dairyP1: Yup.bool()
            .test('dairyP1', validationMsgAtLeastOne, function (x) {
                return (!this.parent.dairyP1 && !this.parent.dairyP2)
                    ? false : true;
            }),
        dairyP2: Yup.bool()
            .test('dairyP2', validationMsgAtLeastOne, function (x) {
                return (!this.parent.dairyP2 && !this.parent.dairyP1)
                    ? false : true;
            }),
        dairyProduct1: Yup.bool()
            .test('dairyProduct1', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyP2 == true && !this.parent.dairyProduct1 && !this.parent.dairyProduct2 && !this.parent.dairyProduct3)
                    ? false : true;
            }),
        dairyProduct2: Yup.bool()
            .test('dairyProduct2', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyP2 == true && !this.parent.dairyProduct1 && !this.parent.dairyProduct2 && !this.parent.dairyProduct3)
                    ? false : true;
            }),
        dairyProduct3: Yup.bool()
            .test('dairyProduct3', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyP2 == true && !this.parent.dairyProduct1 && !this.parent.dairyProduct2 && !this.parent.dairyProduct3)
                    ? false : true;
            }),
        dairyB_1: Yup.bool()
            .test('dairyB_1', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyP1 == true && !this.parent.dairyB_1 && !this.parent.dairyB_2 && !this.parent.dairyB_3)
                    ? false : true;
            }),
        dairyB_2: Yup.bool()
            .test('dairyB_2', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyP1 == true && !this.parent.dairyB_1 && !this.parent.dairyB_2 && !this.parent.dairyB_3)
                    ? false : true;
            }),
        dairyB_3: Yup.bool()
            .test('dairyB_3', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyP1 == true && !this.parent.dairyB_1 && !this.parent.dairyB_2 && !this.parent.dairyB_3)
                    ? false : true;
            }),
        dairyB2_1: Yup.bool()
            .test('dairyB2_1', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyP2 == true && !this.parent.dairyB2_1 && !this.parent.dairyB2_2 && !this.parent.dairyB2_3)
                    ? false : true;
            }),
        dairyB2_2: Yup.bool()
            .test('dairyB2_2', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyP2 == true && !this.parent.dairyB2_1 && !this.parent.dairyB2_2 && !this.parent.dairyB2_3)
                    ? false : true;
            }),
        dairyB2_3: Yup.bool()
            .test('dairyB2_3', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyP2 == true && !this.parent.dairyB2_1 && !this.parent.dairyB2_2 && !this.parent.dairyB2_3)
                    ? false : true;
            }),
        dairyCMilkType1: Yup.bool()
            .test('dairyCMilkType5', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB_1 == true && (!this.parent.dairyCMilkType1 && !this.parent.dairyCMilkType2 && !this.parent.dairyCMilkType3 && !this.parent.dairyCMilkType4 && !this.parent.dairyCMilkType5))
                    ? false : true;
            }),
        dairyCMilkType2: Yup.bool()
            .test('dairyCMilkType5', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB_1 == true && (!this.parent.dairyCMilkType1 && !this.parent.dairyCMilkType2 && !this.parent.dairyCMilkType3 && !this.parent.dairyCMilkType4 && !this.parent.dairyCMilkType5))
                    ? false : true;
            }),
        dairyCMilkType3: Yup.bool()
            .test('dairyCMilkType5', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB_1 == true && (!this.parent.dairyCMilkType1 && !this.parent.dairyCMilkType2 && !this.parent.dairyCMilkType3 && !this.parent.dairyCMilkType4 && !this.parent.dairyCMilkType5))
                    ? false : true;
            }),
        dairyCMilkType4: Yup.bool()
            .test('dairyCMilkType5', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB_1 == true && (!this.parent.dairyCMilkType1 && !this.parent.dairyCMilkType2 && !this.parent.dairyCMilkType3 && !this.parent.dairyCMilkType4 && !this.parent.dairyCMilkType5))
                    ? false : true;
            }),
        dairyCMilkType5: Yup.bool()
            .test('dairyCMilkType5', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB_1 == true && (!this.parent.dairyCMilkType1 && !this.parent.dairyCMilkType2 && !this.parent.dairyCMilkType3 && !this.parent.dairyCMilkType4 && !this.parent.dairyCMilkType5))
                    ? false : true;
            }),
        dairyC2MilkType1: Yup.bool()
            .test('dairyCMilkType1', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB2_1 == true && (!this.parent.dairyC2MilkType1 && !this.parent.dairyC2MilkType2 && !this.parent.dairyC2MilkType3 && !this.parent.dairyC2MilkType4 && !this.parent.dairyC2MilkType5))
                    ? false : true;
            }),
        dairyC2MilkType2: Yup.bool()
            .test('dairyCMilkType2', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB2_1 == true && (!this.parent.dairyC2MilkType1 && !this.parent.dairyC2MilkType2 && !this.parent.dairyC2MilkType3 && !this.parent.dairyC2MilkType4 && !this.parent.dairyC2MilkType5))
                    ? false : true;
            }),
        dairyC2MilkType3: Yup.bool()
            .test('dairyCMilkType3', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB2_1 == true && (!this.parent.dairyC2MilkType1 && !this.parent.dairyC2MilkType2 && !this.parent.dairyC2MilkType3 && !this.parent.dairyC2MilkType4 && !this.parent.dairyC2MilkType5))
                    ? false : true;
            }),
        dairyC2MilkType4: Yup.bool()
            .test('dairyCMilkType4', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB2_1 == true && (!this.parent.dairyC2MilkType1 && !this.parent.dairyC2MilkType2 && !this.parent.dairyC2MilkType3 && !this.parent.dairyC2MilkType4 && !this.parent.dairyC2MilkType5))
                    ? false : true;
            }),
        dairyC2MilkType5: Yup.bool()
            .test('dairyCMilkType5', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB2_1 == true && (!this.parent.dairyC2MilkType1 && !this.parent.dairyC2MilkType2 && !this.parent.dairyC2MilkType3 && !this.parent.dairyC2MilkType4 && !this.parent.dairyC2MilkType5))
                    ? false : true;
            }),
        dairyCStatements: Yup.array()
            .test('dairyCStatements', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB_1 == true && x.length === 0)
                    ? false : true;
            }),
        dairyC2Statements: Yup.array()
            .test('dairyC2Statements', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyCStatements.includes("2") && x.length === 0)
                    ? false : true;
            }),
        dairyBCountryOrigin: Yup.array()
            .test('dairyB_2', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB_2 == true && x.length === 0)
                    ? false : true
            }),
        dairyCStatements2: Yup.array()
            .test('dairyCStatements2', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB2_1 == true && x.length === 0)
                    ? false : true;
            }),
        dairyC2Statements2: Yup.array()
            .test('dairyC2Statements2', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyCStatements2.includes("2") && x.length === 0)
                    ? false : true;
            }),
        dairyB2CountryOrigin: Yup.array()
            .test('dairyB2_2', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyB2_2 == true && x.length === 0)
                    ? false : true
            }),
        dairyProductCountryOrigin: Yup.array()
            .test('dairyProduct2', validationMsgAtLeastOne, function (x) {
                return (this.parent.dairyProduct2 == true && x.length === 0)
                    ? false : true
            })
    });


    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let submitData = values;
            submitData.version = formVersion;
            console.log("submitData")
            console.log(submitData)
            console.log("submitData")

            if (apiValues.length === 0) {
                ProductDairyService.create(submitData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.logCertForm(
                            'Dairy 8468',
                            8468,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            else {
                ProductDairyService.update(productId, values)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.logCertForm(
                            'Dairy 8468',
                            8468,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            setFormSubmitted(true);
        }, 1000);
    }

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);
    let filteredCountries = null;
    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {


            if (!values?.dairyB_2 == true) {
                values.dairyBCountryOrigin = [];
            }
            if (!values?.dairyB2_2 == true) {
                values.dairyB2CountryOrigin = [];
            }
            if (!values?.dairyCStatements.includes("2") || values?.dairyB_1 == false) {
                values.dairyC2Statements = [];
            }
            if (!values?.dairyCStatements2.includes("2") || values?.dairyB2_1 == false) {
                values.dairyC2Statements2 = [];
            }
            if (!values?.dairyB_1 == true) {
                values.dairyCStatements = [];
                values.dairyCMilkType1 = false;
                values.dairyCMilkType2 = false;
                values.dairyCMilkType3 = false;
                values.dairyCMilkType4 = false;
                values.dairyCMilkType5 = false;
            }
            if (!values?.dairyB2_1 == true) {
                values.dairyCStatements2 = [];
                values.dairyC2MilkType1 = false;
                values.dairyC2MilkType2 = false;
                values.dairyC2MilkType3 = false;
                values.dairyC2MilkType4 = false;
                values.dairyC2MilkType5 = false;
            }
            setFieldValue("coldStoreEstablishments", values?.coldStoreEstablishments)
            setFieldValue("rewrappingEstablishments", values?.rewrappingEstablishments)
            console.info("check values");
            filteredCountries = zones.filter(zone =>
                !values.dairyACountryOrigin.some(selectedCountry =>
                    selectedCountry.id === zone.id
                )
            );
        }, [
            values
        ]
        );
        return null;
    };
    const extractIsoCodes = (values, memberStates) => {
        const isoCodes = [];

        if (values?.dairyB_1) isoCodes.push(...values.dairyACountryOrigin.map(x => x.isoCode));
        if (values?.dairyB_2) isoCodes.push(...values.dairyBCountryOrigin.map(x => x.isoCode));
        if (values?.dairyB2_2) isoCodes.push(...values.dairyB2CountryOrigin.map(x => x.isoCode));
        if (values?.dairyB_3) isoCodes.push(...memberStates.map(x => x.isoCode));
        return isoCodes.map(str => str.replace(/-(0|1|2|3|4|5)/g, ''));
    };

    useEffect(() => {
        ProductService.getProductDetailByProductId(productId)
            .then(productResponse => {
                //Get site operatorId
                SiteService.getById(productResponse.data.siteId)
                    .then(siteResponse => {
                        //setSiteEstablishment(response2.data)
                        if (siteResponse.data.establishmentTypeName == "Approval number") {
                            //Get establishment data from operatorid
                            EstablishmentsService.getEstablishmentsDetails([siteResponse.data.tracesId])
                                .then(establishmentResponse => {
                                    //set parent site for this product
                                    setSiteEstablishment(establishmentResponse.data)
                                }, [])
                                .catch(e => {
                                    console.log(e);
                                });
                        }
                        else {
                            const site = [{
                                "approvalNumber": siteResponse.data.establishmentNumber,
                                "name": siteResponse.data.siteName
                            }]
                            setSiteEstablishment(site);
                        }
                    }, [])
                    .catch(e => {
                        console.log(e);
                    });
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductDairyService.getByProductId(productId)
            .then(response => {
                var res = response.data;
                if (response.data == "") {
                    return;
                }
                SetApiValues(response.data);
                setEditing(true);
                setReady(true);
                console.log("init values:" + apiValues);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ProductService.getById(productId)
            .then(response => {
                SetProduct(response.data.data);
                setIsGEFS(response.data.data.isGEFS);
            }, [])
            .catch(e => {
                console.log(e);
            });
        ZoneService.getAllByGroup("ALL_DAIRY_PRODUCT")
            .then((response) => {
                const options = response.data.map(d => ({
                    "id": d.id,
                    "displayName": d.displayName,
                    "countryName": d.countryName,
                    "regionName": d.regionName,
                    "isoCode": d.isoCode,
                })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                const firstItem = "GB-0";
                options.sort((x, y) => { return x.isoCode === firstItem ? -1 : y.isoCode === firstItem ? 1 : 0; });
                SetZones(options);
            })
            .catch((e) => {
                return null;
                console.log(e);
            });
        ZoneService
            .getAllByGroup("ALL_MEMBER_STATES")
            .then((_countryResponse) => {
                const options = _countryResponse.data.map(d => ({
                    "id": d.id,
                    "displayName": d.displayName,
                    "countryName": d.countryName,
                    "regionName": d.regionName,
                    "isoCode": d.isoCode,
                })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                SetMemberStates(options);
            }).catch((e) => {
                console.log(e);
            })
    }, []);

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>
            {formSubmitted ? (
                <SubmissionPage
                    productId={productId}
                    attestationId={attestationId}
                    isGEFS={isGEFS}
                    errors={errors}
                    title={(editing ? "Edited" : "Completed") + " Dairy 8468 Form"}
                    message={`Dairy information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                />
            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />
                                        <div className="">
                                            <ul className="list-unstyled">
                                                <span className='text-warning'>
                                                    If using a third party logistics company to store or pack products please list EU approval number(s)
                                                </span>
                                                <li>
                                                    <div className="pb-4">
                                                        <MultiSelectField
                                                            id='dairyACountryOrigin'
                                                            name='dairyACountryOrigin'
                                                            label="The dairy product originates from:"
                                                            placeholder="Select fields"
                                                            options={zones}
                                                            defaultValues={props.values?.dairyACountryOrigin}
                                                            getOptionValue={option => option.id}
                                                            getOptionLabel={option => option.displayName}
                                                            value={props.values.dairyACountryOrigin}
                                                            isMulti={true}
                                                            onChange={props.setFieldValue}
                                                            onBlur={props.setFieldTouched}
                                                            touched={props.touched.dairyACountryOrigin}
                                                            error={props.errors.dairyACountryOrigin}
                                                            isClearable={true}
                                                            isrequired="true"
                                                            backspaceRemovesValue={true}
                                                            readOnly={readOnly}
                                                            col={6}

                                                        />
                                                    </div>
                                                </li>
                                                <li>
                                                    product obtained from <strong>animals</strong> of which species? <span className="required" />
                                                    <Row className="mb-4">
                                                        <Col sm={6} lg={4} xxl={2} className="button">
                                                            <FormController
                                                                control="checkbox"
                                                                className="border-chk"
                                                                label={Dairy8468Labels.dairySpecies[0].key}
                                                                name={Dairy8468Labels.dairySpecies[0].fieldname}
                                                                readOnly={readOnly}
                                                            />
                                                        </Col>
                                                        <Col sm={6} lg={4} xxl={2} className="button">
                                                            <FormController
                                                                control="checkbox"
                                                                className="border-chk"
                                                                label={Dairy8468Labels.dairySpecies[1].key}
                                                                name={Dairy8468Labels.dairySpecies[1].fieldname}
                                                                readOnly={readOnly}
                                                            />
                                                        </Col>
                                                        <Col sm={6} lg={4} xxl={2} className="button">
                                                            <FormController
                                                                control="checkbox"
                                                                className="border-chk"
                                                                label={Dairy8468Labels.dairySpecies[2].key}
                                                                name={Dairy8468Labels.dairySpecies[2].fieldname}
                                                                readOnly={readOnly}
                                                            />
                                                        </Col>
                                                        <Col sm={6} lg={4} xxl={2} className="button">
                                                            <FormController
                                                                control="checkbox"
                                                                className="border-chk"
                                                                label={Dairy8468Labels.dairySpecies[3].key}
                                                                name={Dairy8468Labels.dairySpecies[3].fieldname}
                                                                readOnly={readOnly}
                                                            />
                                                        </Col>
                                                        <Col sm={6} lg={4} xxl={3} className="button">
                                                            <FormController
                                                                control="checkbox"
                                                                className="border-chk"
                                                                label={Dairy8468Labels.dairySpecies[4].key}
                                                                name={Dairy8468Labels.dairySpecies[4].fieldname}
                                                                readOnly={readOnly}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </li>
                                                <li>
                                                    <FormController
                                                        control="checkbox"
                                                        label={props.values.dairyP1 ? '' : Dairy8468Labels.dairyParentStatement[0].key} // Hide label if dairyP1 is true
                                                        name="dairyP1"
                                                        readOnly={readOnly}
                                                        className="mb-4"
                                                        isRequired={true}
                                                    />
                                                </li>
                                                {props.values.dairyP1 &&
                                                    <div className="border-left pl-3 subform">
                                                        <>
                                                            <li className="mt-3"> The <strong>Raw Milk</strong> originated from:</li>
                                                            <br></br>
                                                            <li>
                                                                <FormController
                                                                    control="checkbox"
                                                                    label={Dairy8468Labels.dairyBStatementsChoice[0].key}
                                                                    name="dairyB_1"
                                                                    readOnly={readOnly}
                                                                    className="mb-4"
                                                                />
                                                            </li>
                                                            {props.values.dairyB_1 &&
                                                                <div className="border-left pl-3 subform">
                                                                    <>
                                                                        <li className="mt-3">
                                                                            obtained from <strong>animals</strong> of which species? (select all that apply):<span className="required" />
                                                                            <Row>
                                                                                <Col sm={6} lg={4} xxl={2} className="button">
                                                                                    <FormController
                                                                                        control="checkbox"
                                                                                        className="border-chk"
                                                                                        label={Dairy8468Labels.dairyCMilkChoices[0].key}
                                                                                        name={Dairy8468Labels.dairyCMilkChoices[0].fieldname}
                                                                                        readOnly={readOnly}
                                                                                    />
                                                                                </Col>
                                                                                <Col sm={6} lg={4} xxl={2} className="button">
                                                                                    <FormController
                                                                                        control="checkbox"
                                                                                        className="border-chk"
                                                                                        label={Dairy8468Labels.dairyCMilkChoices[1].key}
                                                                                        name={Dairy8468Labels.dairyCMilkChoices[1].fieldname}
                                                                                        readOnly={readOnly}
                                                                                    />
                                                                                </Col>
                                                                                <Col sm={6} lg={4} xxl={2} className="button">
                                                                                    <FormController
                                                                                        control="checkbox"
                                                                                        className="border-chk"
                                                                                        label={Dairy8468Labels.dairyCMilkChoices[2].key}
                                                                                        name={Dairy8468Labels.dairyCMilkChoices[2].fieldname}
                                                                                        readOnly={readOnly}
                                                                                    />
                                                                                </Col>
                                                                                <Col sm={6} lg={4} xxl={2} className="button">
                                                                                    <FormController
                                                                                        control="checkbox"
                                                                                        className="border-chk"
                                                                                        label={Dairy8468Labels.dairyCMilkChoices[3].key}
                                                                                        name={Dairy8468Labels.dairyCMilkChoices[3].fieldname}
                                                                                        readOnly={readOnly}
                                                                                    />
                                                                                </Col>
                                                                                <Col sm={6} lg={4} xxl={3} className="button">
                                                                                    <FormController
                                                                                        control="checkbox"
                                                                                        className="border-chk"
                                                                                        label={Dairy8468Labels.dairyCMilkChoices[4].key}
                                                                                        name={Dairy8468Labels.dairyCMilkChoices[4].fieldname}
                                                                                        readOnly={readOnly}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </li>

                                                                        <li>
                                                                            <div className="clause-container pl-0 ms-0 mt-3">
                                                                                <FormController
                                                                                    control="checkboxClauses"
                                                                                    label="that:"
                                                                                    isrequired={"true"}
                                                                                    name="dairyCStatements"
                                                                                    options={Dairy8468Labels.dairyCStatementsChoice}
                                                                                    readOnly={readOnly}
                                                                                />

                                                                                <div className='subform ps-5 mt-3'>
                                                                                    <FormController
                                                                                        control="checkboxClauses"
                                                                                        name="dairyC2Statements"
                                                                                        disabled={!props.values.dairyCStatements.includes("2")}
                                                                                        options={Dairy8468Labels.dairyC2StatementsChoice}
                                                                                        readOnly={readOnly}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </>
                                                                    <hr />
                                                                </div>
                                                            }
                                                            <li>
                                                                <FormController
                                                                    control="checkbox"
                                                                    label={Dairy8468Labels.dairyBStatementsChoice[1].key}
                                                                    name="dairyB_2"
                                                                    readOnly={readOnly}
                                                                    className="mb-3"
                                                                />
                                                            </li>
                                                            <li>
                                                                {props.values.dairyB_2 &&

                                                                    <div className="border-left pl-3 subform">
                                                                        <MultiSelectField
                                                                            id='dairyBCountryOrigin'
                                                                            name='dairyBCountryOrigin'
                                                                            label="Zone(s) for Raw milk"
                                                                            placeholder="Select fields"
                                                                            options={filteredCountries}
                                                                            defaultValues={props.values?.dairyBCountryOrigin}
                                                                            getOptionValue={option => option.id}
                                                                            getOptionLabel={option => option.displayName}
                                                                            value={props.values.dairyBCountryOrigin}
                                                                            isMulti={true}
                                                                            onChange={props.setFieldValue}
                                                                            onBlur={props.setFieldTouched}
                                                                            touched={props.touched.dairyBCountryOrigin}
                                                                            error={props.errors.dairyBCountryOrigin}
                                                                            isClearable={true}
                                                                            isrequired="true"
                                                                            backspaceRemovesValue={true}
                                                                            readOnly={readOnly}
                                                                            col={6}
                                                                        />
                                                                        <hr />
                                                                    </div>
                                                                }
                                                            </li>
                                                            <li>
                                                                <FormController
                                                                    control="checkbox"
                                                                    label={Dairy8468Labels.dairyBStatementsChoice[2].key}
                                                                    name="dairyB_3"
                                                                    readOnly={readOnly}
                                                                    className="mb-3"
                                                                />
                                                            </li>

                                                        </>
                                                        <hr />
                                                    </div>
                                                }
                                                <li>
                                                    <FormController
                                                        control="checkbox"
                                                        label={props.values.dairyP2 ? '' : Dairy8468Labels.dairyParentStatement[1].key} // Hide label if dairyP1 is true
                                                        name="dairyP2"
                                                        readOnly={readOnly}
                                                        className="mb-4"
                                                        isRequired={true}
                                                    />
                                                </li>
                                                {props.values.dairyP2 &&
                                                    <div className="border-left pl-3 subform">
                                                        <li><strong>Dairy Products</strong> produced in:</li>
                                                        <br></br>
                                                        <>
                                                            <div className="border-left pl-3 subform">
                                                                <li>
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={Dairy8468Labels.dairyProductStatementsChoice[0].key}
                                                                        name="dairyProduct1"
                                                                        readOnly={readOnly}
                                                                        className="mb-3"
                                                                    />
                                                                </li>
                                                                <li>
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={Dairy8468Labels.dairyProductStatementsChoice[1].key}
                                                                        name="dairyProduct2"
                                                                        readOnly={readOnly}
                                                                        className="mb-3"
                                                                    />
                                                                </li>
                                                                <li>
                                                                    {props.values.dairyProduct2 &&

                                                                        <div className="border-left pl-3 subform">
                                                                            <MultiSelectField
                                                                                id='dairyProductCountryOrigin'
                                                                                name='dairyProductCountryOrigin'
                                                                                label="Zone(s) for Dairy Product"
                                                                                placeholder="Select fields"
                                                                                options={filteredCountries}
                                                                                defaultValues={props.values?.dairyProductCountryOrigin}
                                                                                getOptionValue={option => option.id}
                                                                                getOptionLabel={option => option.displayName}
                                                                                value={props.values.dairyProductCountryOrigin}
                                                                                isMulti={true}
                                                                                onChange={props.setFieldValue}
                                                                                onBlur={props.setFieldTouched}
                                                                                touched={props.touched.dairyProductCountryOrigin}
                                                                                error={props.errors.dairyProductCountryOrigin}
                                                                                isClearable={true}
                                                                                isrequired="true"
                                                                                backspaceRemovesValue={true}
                                                                                readOnly={readOnly}
                                                                                col={6}
                                                                            />
                                                                            <hr />
                                                                        </div>
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <FormController
                                                                        control="checkbox"
                                                                        label={Dairy8468Labels.dairyProductStatementsChoice[2].key}
                                                                        name="dairyProduct3"
                                                                        readOnly={readOnly}
                                                                        className="mb-3"
                                                                    />
                                                                </li>
                                                                <hr />
                                                            </div>
                                                            <br></br>

                                                            <li>obtained <strong>from raw milk </strong>originating from:</li>
                                                            <br></br>

                                                            <div className="border-left pl-3 subform">
                                                                <>
                                                                    <li>
                                                                        <FormController
                                                                            control="checkbox"
                                                                            label={Dairy8468Labels.dairyBStatementsChoice2[0].key}
                                                                            name="dairyB2_1"
                                                                            readOnly={readOnly}
                                                                            className="mb-4"
                                                                        />
                                                                    </li>
                                                                    {props.values.dairyB2_1 &&
                                                                        <div className="border-left pl-3 subform">
                                                                            <>
                                                                                <li className="mt-3">
                                                                                    obtained from <strong>animals</strong> of which species? <span className="required" />
                                                                                    <Row>
                                                                                        <Col sm={6} lg={4} xxl={2} className="button">
                                                                                            <FormController
                                                                                                control="checkbox"
                                                                                                className="border-chk"
                                                                                                label={Dairy8468Labels.dairyCMilkChoices2[0].key}
                                                                                                name={Dairy8468Labels.dairyCMilkChoices2[0].fieldname}
                                                                                                readOnly={readOnly}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col sm={6} lg={4} xxl={2} className="button">
                                                                                            <FormController
                                                                                                control="checkbox"
                                                                                                className="border-chk"
                                                                                                label={Dairy8468Labels.dairyCMilkChoices2[1].key}
                                                                                                name={Dairy8468Labels.dairyCMilkChoices2[1].fieldname}
                                                                                                readOnly={readOnly}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col sm={6} lg={4} xxl={2} className="button">
                                                                                            <FormController
                                                                                                control="checkbox"
                                                                                                className="border-chk"
                                                                                                label={Dairy8468Labels.dairyCMilkChoices2[2].key}
                                                                                                name={Dairy8468Labels.dairyCMilkChoices2[2].fieldname}
                                                                                                readOnly={readOnly}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col sm={6} lg={4} xxl={2} className="button">
                                                                                            <FormController
                                                                                                control="checkbox"
                                                                                                className="border-chk"
                                                                                                label={Dairy8468Labels.dairyCMilkChoices2[3].key}
                                                                                                name={Dairy8468Labels.dairyCMilkChoices2[3].fieldname}
                                                                                                readOnly={readOnly}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col sm={6} lg={4} xxl={3} className="button">
                                                                                            <FormController
                                                                                                control="checkbox"
                                                                                                className="border-chk"
                                                                                                label={Dairy8468Labels.dairyCMilkChoices2[4].key}
                                                                                                name={Dairy8468Labels.dairyCMilkChoices2[4].fieldname}
                                                                                                readOnly={readOnly}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </li>

                                                                                <li>
                                                                                    <div className="clause-container pl-0 ms-0 mt-3">
                                                                                        <FormController
                                                                                            control="checkboxClauses"
                                                                                            label="that:"
                                                                                            isrequired={"true"}
                                                                                            name="dairyCStatements2"
                                                                                            options={Dairy8468Labels.dairyCStatementsChoice2}
                                                                                            readOnly={readOnly}
                                                                                        />

                                                                                        <div className='subform ps-5 mt-3'>
                                                                                            <FormController
                                                                                                control="checkboxClauses"
                                                                                                name="dairyC2Statements2"
                                                                                                disabled={!props.values.dairyCStatements2.includes("2")}
                                                                                                options={Dairy8468Labels.dairyC2StatementsChoice2}
                                                                                                readOnly={readOnly}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </>
                                                                            <hr />
                                                                        </div>
                                                                    }
                                                                    <li>
                                                                        <FormController
                                                                            control="checkbox"
                                                                            label={Dairy8468Labels.dairyBStatementsChoice2[1].key}
                                                                            name="dairyB2_2"
                                                                            readOnly={readOnly}
                                                                            className="mb-3"
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        {props.values.dairyB2_2 &&

                                                                            <div className="border-left pl-3 subform">
                                                                                <MultiSelectField
                                                                                    id='dairyB2CountryOrigin'
                                                                                    name='dairyB2CountryOrigin'
                                                                                    label="Zone(s) for Raw milk"
                                                                                    placeholder="Select fields"
                                                                                    options={filteredCountries}
                                                                                    defaultValues={props.values?.dairyB2CountryOrigin}
                                                                                    getOptionValue={option => option.id}
                                                                                    getOptionLabel={option => option.displayName}
                                                                                    value={props.values.dairyB2CountryOrigin}
                                                                                    isMulti={true}
                                                                                    onChange={props.setFieldValue}
                                                                                    onBlur={props.setFieldTouched}
                                                                                    touched={props.touched.dairyB2CountryOrigin}
                                                                                    error={props.errors.dairyB2CountryOrigin}
                                                                                    isClearable={true}
                                                                                    isrequired="true"
                                                                                    backspaceRemovesValue={true}
                                                                                    readOnly={readOnly}
                                                                                    col={6}
                                                                                />
                                                                                <hr />
                                                                            </div>
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        <FormController
                                                                            control="checkbox"
                                                                            label={Dairy8468Labels.dairyBStatementsChoice2[2].key}
                                                                            name="dairyB2_3"
                                                                            readOnly={readOnly}
                                                                            className="mb-3"
                                                                        />
                                                                    </li>

                                                                </>
                                                            </div></>
                                                        <hr />
                                                    </div>
                                                }
                                                {(props.values.dairyP1 === true || props.values.dairyP2 === true) &&
                                                    <>
                                                        <li>
                                                            <ColdStoreSelect
                                                                name="coldStoreEstablishments"
                                                                selectedFieldName="coldStoreEstablishments"
                                                                setEstablishmentSelected={props.setFieldValue}
                                                                selections={props.values.coldStoreEstablishments}
                                                                label="Cold Store(s)"
                                                                isMulti={true}
                                                                countries={extractIsoCodes(props.values, memberStates)}
                                                                certificateNumber={certificateNumber}
                                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                                isRequired={false}
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                        <li>
                                                            <RewrappingEstablishment
                                                                name="rewrappingEstablishments"
                                                                selectedFieldName="rewrappingEstablishments"
                                                                setEstablishmentSelected={props.setFieldValue}
                                                                selections={props.values.rewrappingEstablishments}
                                                                label="Re-wrapping and re-packaging establishment"
                                                                isMulti={true}
                                                                countries={extractIsoCodes(props.values, memberStates)}
                                                                certificateNumber={certificateNumber}
                                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                                isRequired={false}
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                        <li>
                                                            <MultiSelectField
                                                                id='identificationMarkEstablishments'
                                                                name='identificationMarkEstablishments'
                                                                label="Identification mark"
                                                                placeholder="Select fields"
                                                                options={siteEstablishment?.concat(props.values.coldStoreEstablishments, props.values.rewrappingEstablishments)}
                                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                                isMulti={false}
                                                                onChange={props.setFieldValue}
                                                                onBlur={props.setFieldTouched}
                                                                touched={props.touched.identificationMarkEstablishments}
                                                                error={props.errors.identificationMarkEstablishments}
                                                                isClearable={true}
                                                                isrequired="true"
                                                                backspaceRemovesValue={true}
                                                                readOnly={readOnly}
                                                                col={6}
                                                            />
                                                        </li>
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <hr />
                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" disabled={isSubmitting || readOnly}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />
                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Dairy8468AddPage;