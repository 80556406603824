import { faCheck, faEllipsisH, faFileContract, faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Col, Dropdown, Row, Table } from '@themesberg/react-bootstrap';
import { Form, Formik } from 'formik';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import PermissionsCheck from '../../../auth/PermissionCheck';
import FormController from "../../../pages/components/common/FormController";
import Paging from "../../../pages/components/common/Paging";
import StorageDeclarationService from "../../../pages/services/StorageDeclarationService";
import BlobService from "../../../pages/services/BlobService";
import ManufacturerService from "../../../pages/services/ManufacturerService";
import { Routes } from "../../../routes";
import Preloader from "../../Preloader";
import { NoResults } from "../NoResults";
import StorageExporterDeclarationService from '../../../pages/services/StorageExporterDeclarationService';
  
export const StorageDeclarationSelectTable = (props) => {
  const [count, setCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const {
    setFormChanged = false,
    contentsSelected,
    totalPages = count,
    size = "md", 
    withIcons = false, 
    isSelectForm = true, 
    onChange, 
  } = props;

  const [storageDeclarations, setStorageDeclarations] = useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [loaded, setLoaded] = useState(false);
  const [manufacturers, setManufacturers] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const history = useHistory();

  const initialValues = {
    searchTerms: '',
    manufacturerId:'',
    showStorageDeclaration:true,
  }

  const [filterValues, setFilterValues] = useState(initialValues);

  function onSubmit(values, { setSubmitting, resetForm } ) {
      setTimeout(() => {
          setActivePage(1);
          setFilterValues(values);
          retrieveStorageDeclarations(values);
          console.log(JSON.stringify(values, null, 2));
          setSubmitting(false);
      }, 1000);
  }

  useEffect(() => {
    if(isSelectForm){
    }
    getManufacturerData();
  },[isSelectForm]);
  
  async function getManufacturerData(){
    ManufacturerService.getAll()
      .then((response) => {
          const options = response.data.map(d => ({
              "value": d.id,
              "label": d.manufacturerName
          })).sort((a, b) => a.label.localeCompare(b.label));
          setManufacturers(options);
      })
      .catch((e) => {
          console.log(e);
      });
  }

  const retrieveStorageDeclarations = (values = null) => {
    let params =  '';
    const currentValues = values || filterValues;
    params = getFilterParams(currentValues.searchTerms, currentValues.manufacturerId, activePage, pageSize);
    getStorageDeclarationsData(params);
  };

  useEffect(() => {
      retrieveStorageDeclarations();
  },[activePage, pageSize]);

  useEffect(() => {
    if(contentsSelected != []){
      setFormChanged(true);
    }
    else{
      setFormChanged(false);
    }
  },[contentsSelected]);

  const getFilterParams = (searchTerms, manufacturerId, activePage, pageSize) => {
      let params = {};
      if (searchTerms) {
          params["searchTerms"] = searchTerms;
      }
      if (manufacturerId) {
        params["manufacturerId"] = manufacturerId;
      } 
      if (activePage) {
          params["page"] = activePage;
      }
      if (pageSize) {
          params["size"] = pageSize;
      }
      return params;
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value.value);
    setActivePage(1);
  };

  const openBlobPdf = (filename) => {
    BlobService.openBlobPdf(filename);
  }

  const getStorageDeclarationsData = (params) => {
    setLoaded(false);
    StorageExporterDeclarationService.getAllPaged(params)
        .then((response) => {
            const { data, totalPages, totalRecords} = response.data;
            setTotalItems(totalRecords);
            setStorageDeclarations(data);
            setCount(totalPages);
            setLoaded(true);
        })
        .catch((e) => {
            setLoaded(true);
            console.log(e);
        });
  };
  
  const TableRow = (props) => {
    const {
      id, 
      batches,
      storageSupplierDeclaration, 
      productName = JSON.parse(storageSupplierDeclaration.productJson).product?.productName, 
      manufacturer,
      uniqueCode,
      manufacturerName = manufacturer?.manufacturerName, 
      signedDocument,
      certificateNumber,
      validTo, 
      validFrom,
      selections
    } = props;
    const [selected, setSelected] = useState(false);

    useEffect(() => {
      if(isSelected()){
        setSelected(true)
      }
      else{
        setSelected(false)
      }
    }, []);

    const isSelected = () => {
      if(selections!==undefined){
        const found = selections.find(x => x.id == id );
        if(found!== undefined && found !== null){
          return true;
        }
      }
      return false;
    }

    const getSADate = (date) => {
      var newDate = moment(date).format("DD/MM/YY");
      if (newDate === "Invalid date") {
          return "";
      }
      return newDate;
    }
    
    const selectItem = (id, productName, uniqueCode, manufacturerName, signedDocument, batchNumbers, certificateNumbers, dailyAttestationItems) => { 
      var res = contentsSelected;
      var item = {
          id: id, 
          isCompound: false, 
          isStorageDeclaration: true, 
          productName: productName,
          dailyAttestationId: id, 
          uniqueCode: uniqueCode, 
          batchNumbers: batchNumbers,
          certificateNumbers: [certificateNumbers?.number.toString()],
          numberOfPackages: props.numberPackages,
          manufacturerName: manufacturerName, 
          deliveryDate: null, 
          commodityCode: JSON.parse(storageSupplierDeclaration.productJson)?.product?.productDetails?.commodityHscode,
          netWeightTotal: (props.numberPackages * JSON.parse(storageSupplierDeclaration.productJson)?.product?.productDetails?.netWeight).toFixed(3),
          grossWeightTotal: (props.numberPackages * JSON.parse(storageSupplierDeclaration.productJson)?.product?.productDetails?.grossWeight).toFixed(3),
          dailyAttestationItems: props, 
          productJson: storageSupplierDeclaration.productJson,
          isGEFS: false,
          isStorageDeclaration: true,
          attestation: null,
          signedDocument: signedDocument, 
          combinations: [],
          consignmentContentsId: uuid()
        };

        if(!selected)
          {
            setSelected(true);
            setFormChanged(true);
            var hasMatch = contentsSelected.find(x=>x?.id == item?.id);
            if(hasMatch == undefined){
              res.push(item);
            }
          }
          else{
            res.pop(item);
            setSelected(false);
          }
    }

    return (
      <tr className={selected ? "bg-lightblue" : null}>
      <td id={id}>
            {uniqueCode}
      </td>
      <td id={id}>
        <span className="fw-normal">
          {productName}
        </span>
        </td>
      <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
        <td id={id}>
        <span className="fw-normal">
          {manufacturerName}
        </span>
        </td>
      </PermissionsCheck>
      <td id={id}  className="text-center">
        <span className="fw-normal">
          {batches}
        </span>
      </td>
      <td colSpan={2} id={id} className="text-center">
        <span className="fw-normal">
          {getSADate(validFrom)} 
          &nbsp;to&nbsp; 
          {getSADate(validTo)}
        </span>
      </td>   
      <td>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="position-absolute">
            <Dropdown.Item onClick={()=>{openBlobPdf(signedDocument?.filename)}}>
              <FontAwesomeIcon icon={faFileContract} className="dropdown-item-icon" />
              Signed Declaration
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      {isSelectForm &&
        <td colSpan={2} className="text-right px-4">
          {selected?
          (
              <Button variant="primary" size="sm" className="text-center" 
                onClick={() => selectItem(id, productName, uniqueCode,manufacturerName, signedDocument, batches, certificateNumber)}>                
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            )
            :
            (
              <Button variant="light" size="sm" className="text-center"  
                onClick={() => selectItem(id, productName, uniqueCode,manufacturerName, signedDocument, batches, certificateNumber)}>
                <FontAwesomeIcon icon={faPlus}/>
              </Button>
            )
          }
        </td>
      }
    </tr>
    );
  };

  //Start - Paging Functions
  const onPrevItem = () => {
    const prevActiveItem = activePage === 1 ? activePage : activePage - 1;
    setActivePage(prevActiveItem);
  };

  const onPageNumberClick = (number) => {
    setActivePage(number);
  };
  const onNextItem = () => {
    const nextActiveItem = activePage === count ? activePage : activePage + 1;
    setActivePage(nextActiveItem);
  };
  //End - Paging Functions

  return (
    <>
      <Col xs={12} sm={6} lg={12}>
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            >
            {(props, isSubmitting) => (
              <Form>     
                  <Row className="mb-3">
                      <Col xs={12} sm={8} lg={4}>
                          <FormController
                              control="input"
                              type="text"
                              placeholder="Search by reference or unique document number"
                              name="searchTerms"
                          />
                      </Col>
                      
                      <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
                          <Col xs={12} sm={8} lg={4}>
                            <FormController
                                id="manufacturer"
                                name="manufacturer"
                                placeholder="Select a manufacturer"
                                control="multiselect"
                                options={manufacturers}
                                isMulti={false}
                                isClearable={true}
                                onBlur={props.setFieldTouched}
                                onChange={(name, value) => {
                                  props.setFieldValue('manufacturer',value);
                                  props.setFieldValue('manufacturerId',value?.value);
                                    
                                }}
                                touched={props.touched.manufacturer}
                                errors={props.errors.manufacturer}
                                isrequired="true"
                                col={12}
                            />
                          </Col>
                      </PermissionsCheck>    
                      <Col xs={12} sm={2} lg={2} >
                          <div className="form-group">
                              <Button variant="light" type="submit" disabled={isSubmitting}>
                              <FontAwesomeIcon icon={faFilter} />{isSubmitting ? "Please wait..." : "Filter"}</Button>
                          </div>
                      </Col>
                  </Row>
              </Form>
            )}
        </Formik >
      </Col>
      <Preloader component={true} show={!loaded} />

      <Table hidden={!loaded || storageDeclarations.length === 0 } responsive hover className="user-table align-items-center">
        <thead>
          <tr>
            <th className="border-bottom">Unique code</th>
            <th className="border-bottom">Product Name</th>
            <PermissionsCheck errorProps={{ disabled: true }} requiresRole={['retailer', 'admin', "certifyingofficer"]}>
              <th className="border-bottom">Manufacturer</th>
            </PermissionsCheck>
            <th className="border-bottom text-center">Batch Numbers</th>
            <th colSpan={2} className="border-bottom text-center">Valid dates</th>
            <th  className="border-bottom"></th>
          </tr>
        </thead>
        <tbody>
            {storageDeclarations?.map(t => 
              <TableRow 
                  key={`storageDeclarations-${t.id}`}
                  selections={contentsSelected}
                  {...t} 
              />)}
        </tbody>
      </Table>

      <Paging
        activePage={activePage}
        size={size}
        pageSize={pageSize}
        totalPages={count}
        totalItems={totalItems}
        handleChange={handlePageSizeChange}
        onPrevItem={onPrevItem}
        onNextItem={onNextItem}
        onPageNumberClick={onPageNumberClick}
        withIcons={withIcons}
      />
      {loaded && storageDeclarations?.length === 0 &&(
        <NoResults name="storage declarations"/>
      )}
    </>
  );
};

export default StorageDeclarationSelectTable;
