import React from 'react';
import { MultiSelectField } from "../../../../common/MultiSelect";
import * as AttestationContent from "../../../Common/AttestationContent";
import AttestationBody from "../../../Common/AttestationBody";
import { Card, Col, Row } from "@themesberg/react-bootstrap";
import { useFormikContext } from 'formik';

export default function RequestAttestation(props) {
  const {
    formField: {
        customUniqueRef,
        vetId,
        traceability,
        batchNumbers,
        batchFormat,
        batchRangeFrom,
        batchRangeTo,
        batchNumber,
        finalConsumer,
        productionDate,
        productionDateSingle,
        productionDateMultipleStart,
        productionDateMultipleEnd,
    },
    certificateNumber,
    productId,
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
    user,
    uniqueRef,
    product, 
    manufacturer,
    platform, 
    vets, 
    organisation,
    site, 
    productDetail,
    attestationData, 
    isGEFS,
    temperature
  } = props;
  
  const productionDateChoice =[{value:1, key: "Single production dates"},{value:2, key: "Multiple production dates"}];
  
  const { values: formValues } = useFormikContext();
  return (

    <Row  className="justify-content-left mt-4">
        <Col xl={11}>
            <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                <Card.Body>
                    <AttestationBody
                        finalConsumer={finalConsumer} 
                        traceability={traceability} 
                        isReadOnly={false}  
                        uniqueRef={uniqueRef}
                        customUniqueRef={customUniqueRef}
                        attestationData={attestationData}
                        productId={productId} 
                        user={user} 
                        organisation={organisation}
                        manufacturer={manufacturer}
                        platform={platform}
                        site={site}
                        setFieldValue={setFieldValue}
                        productionDateChoice={productionDateChoice}
                        productionDate={productionDate}
                        productionDateMultipleEnd={productionDateMultipleEnd}
                        productionDateSingle={productionDateSingle}
                        productionDateMultipleStart={productionDateMultipleStart}
                        isGEFS={isGEFS}
                        product={product} 
                        productDetail={productDetail} 
                        certificateNumber={certificateNumber}
                    />
                                
                    <Row className="mt-3">
                        <Col md={12}>
                            <AttestationContent.TraceabilityTable 
                                finalConsumer={finalConsumer} 
                                isReadOnly={false}  
                                certificateNumber={certificateNumber}
                            />
                            <AttestationContent.CertificateFormDetails 
                                certificateNumber={certificateNumber}
                                manufacturer={manufacturer} 
                                productDetail={productDetail}
                                attestationData={attestationData}
                                isGEFS={product.isGEFS}
                                site={site}
                                temperature={temperature}                            
                            />
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
            <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                <Card.Body>
                    <Row className="mt-3">
                        <Col md={12}>
                            <AttestationContent.Batches
                                values={formValues}
                                batchFormat={batchFormat}
                                batchRangeFrom={batchRangeFrom}
                                batchRangeTo={batchRangeTo}
                                batchNumbers={batchNumbers}
                                batchNumber={batchNumber}
                                isReadOnly={false}   
                                isGEFS={isGEFS}
                                onChange={setFieldValue}
                                setFieldValue={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.batchNumbers}
                                error={errors.batchNumbers}
                            />
                            <AttestationContent.Traceability 
                                traceability={traceability} 
                                isReadOnly={false}  
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card border="light" className="bg-white shadow-sm mb-4 p-3">
                <Card.Body>
                    <div>
                        <h5 className="mb-3">Vet Inspection</h5>
                        <MultiSelectField
                            id={vetId.name}
                            name={vetId.name}
                            label={vetId.label}
                            col={4}
                            placeholder="Select a vet by RCVS Number"
                            isrequired={"true"}
                            options={vets}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.rcvsnumber} 
                            isMulti={false}
                            isClearable={true}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.vetId}
                            error={errors.vetId}
                        />
                    </div>
                </Card.Body>
            </Card>
        </Col>
    </Row>
  );
}
