import React  from 'react';
import {Card, Col, Row, Image } from "@themesberg/react-bootstrap";
 import * as AttestationContent from "../../../Common/AttestationContent"
import { useFormikContext } from 'formik';
import AttestationBody from "../../../Common/AttestationBody";
import Logo from "../../../../../../assets/img/logo.PNG";

export default function ResponseAttestationNonGEFSTemplate(props) {
    
  const { values: formValues } = useFormikContext();
  const {
    user,
    values,
    forwardedRef,
    uniqueRef,
    product, 
    manufacturer,
    setFieldValue,
    site, 
    speciesList, 
    attestationData,
    certificateNumber,
    productDetail,
    productId,
    organisation,
    platform,
    isGEFS,
    temperature                          

  } = props;

  return (
    <Card ref={forwardedRef} border="light" className="d-none d-print-blockbg-white shadow-sm mb-4 p-3">
        <Card.Body>
            <Image src={Logo} className="no-print noprint mt-4 mb-5" width="200" />
 
            <AttestationBody
                    finalConsumer={formValues.finalConsumer} 
                    traceability={formValues.traceability} 
                    uniqueRef={uniqueRef}
                    customUniqueRef={formValues.customUniqueRef}
                    attestationData={attestationData}
                    user={user} 
                    productId={productId}
                    organisation={organisation}
                    manufacturer={manufacturer}
                    platform={platform}
                    site={site}
                    productionDateChoice={formValues.productionDateChoice}
                    productionDate={formValues.productionDate}
                    productionDateMultipleEnd={formValues.productionDateMultipleEnd}
                    productionDateSingle={formValues.productionDateSingle}
                    productionDateMultipleStart={formValues.productionDateMultipleStart}
                    isGEFS={isGEFS}
                    product={product} 
                    productDetail={productDetail} 
                    isReadOnly={true}
                    certificateNumber={certificateNumber}
                />
                <Row className="mt-3">
                    <Col md={12}>
                        <AttestationContent.Traceability 
                            traceability={formValues.traceability} 
                            isReadOnly={true}  
                            manufacturer={manufacturer} 
                            productDetail={productDetail}
                            attestationData={attestationData}
                            isrequired={"true"}
                        />
                        <AttestationContent.TraceabilityTable 
                            dateCollectionProduction={formValues.dateCollectionProduction} 
                            finalConsumer={formValues.finalConsumer} 
                            isReadOnly={true}  
                            certificateNumber={certificateNumber}
                            attestationData={attestationData}
                            isrequired={"true"}
                        />
                        <AttestationContent.DateCollectionProductionText
                            dateCollectionProduction={formValues.dateCollectionProduction}
                            isGEFS={product.isGEFS}
                        />
                        <AttestationContent.Batches
                            batchNumber={formValues.batchNumber}
                            isReadOnly={true}   
                            isGEFS={product.isGEFS}
                        />

                        <AttestationContent.AttestationNonGEFSQuestions 
                            dateCollectionProduction={formValues.dateCollectionProduction}  
                            approvedExportCountries={formValues.approvedExportCountries}  
                            approvedExportCountriesOfOrigin={formValues.approvedExportCountriesOfOrigin}
                            numberProductsCases={formValues.numberProductsCases}
                            hatcheryName={formValues.hatcheryName}
                            hatcheries={formValues.hatcheries}
                            isDateRequired={formValues.isDateRequired}
                            isReadOnly={true}   
                        />
                        <AttestationContent.CertificateFormDetails 
                            certificateNumber={certificateNumber}
                            isReadOnly={true}  
                            manufacturer={manufacturer} 
                            productDetail={productDetail}
                            attestationData={attestationData}
                            isGEFS={product.isGEFS}
                            site={site}
                            temperature={temperature}                            
                        />
                    <AttestationContent.VetAuthorisedBy user={user}  manufacturer={manufacturer}  hasSignature={true}/>
                </Col>
            </Row>
          
        </Card.Body>
    </Card>
  );
}
