import React from "react";
import BaseAgGridPage from "../../../../../components/BaseGrid";

function GridTrichinellaTable() {
  return (
    <BaseAgGridPage
      title="Trichinella regions"
      description="This is a list of regions which require Trichinella PH/D103 - Option 3 to be selected"
      dataUrl="https://ivettedstorage.blob.core.windows.net/product-images/TrichinellaTable.json"
      handleStringArrays={true}
      stringColumnHeader="Code"
      stringColumnField="code"
      width="250px"
    />
  );
}

export default GridTrichinellaTable;