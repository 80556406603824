import React, { useState } from "react";
import BreadcrumbNav from "../../../components/BreadcrumbNav";
import { Routes } from "../../../routes";
import { Row, Col, Card, Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import PermissionsCheck from "../../../auth/PermissionCheck";
import { scopes } from "../../../auth/PermissionsMap";

const ToolsDashboardPage = () => {
  const breadcrumbData = {
    item1: {
      name: "Tools",
      link: Routes.ToolsDashboardPage.path,
    },
  };

  return (
    <div>
      <BreadcrumbNav data={breadcrumbData} />
      <h2>Tools</h2>
      <Row>{renderCards()}</Row>
    </div>
  );

  function renderCards() {
    return (
      <PermissionsCheck
        errorProps={{ disabled: true }}
        scopes={[scopes.tools.read]}
      >
        <Col xs={12} lg={6} className="my-4">
          <Card border="light" className="shadow-sm">
            <Card.Header className="fs-4 d-flex text-title justify-content-center">
              <div className={`icon-shape icon-sm rounded me-02`}>
                <FontAwesomeIcon icon={faFileSignature} />
              </div>
              <h4 className="my-2">Certificate Verifier</h4>
            </Card.Header>
            <Card.Body className="text-center">
              <Row>
                <Col md={12} className="mx-auto mb-4 p-5">
                  <p>
                    Upload certificates to perform a digital check for any
                    errors, incompatible clauses and TRACES approved
                    establishments.
                  </p>
                  <div className="form-group mt-4">
                    <Button
                      href={Routes.CertificateCheckerPage.path}
                      type="submit"
                    >
                      Go to Certificate Verifier
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </PermissionsCheck>
    );
  }
};
export default ToolsDashboardPage;
