import React from "react";
import BaseAgGridPage from "../../../../../components/BaseGrid";

const columns = [
  { headerName: "Type", field: "Type", filter: true, flex: 1 },
  { headerName: "Region", field: "Region", filter: true, flex: 1 },
];

function GridBseRegions() {
  return (
    <BaseAgGridPage
      title="BSE regions"
      description="These regions are checked when negligible or controlled BSE risk statements are selected."
      dataUrl="https://ivettedstorage.blob.core.windows.net/product-images/BseRegions.json"
      headers={{ /* optional headers if needed */ }}
      path="" // If your JSON is directly an array, leave path empty
      columns={columns}
      transformData={true}
      height={500}
      width="100%"
    />
  );
}

export default GridBseRegions;