import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import stripJsonComments from 'strip-json-comments';
import FileService from "../pages/services/FileService";

const BaseAgGridPage = ({
  title = "Data Table",
  dataUrl,
  description = "",
  headers = {},
  path = "", // e.g. "data" if your JSON is { data: [...] }
  columns = [],
  height = 500,
  width = "100%",
  transformData = false,
  // Optional props for handling string arrays
  handleStringArrays = false, // If true, transform arrays of strings into array of objects
  stringColumnHeader = "Code",
  stringColumnField = "code"
}) => {
  const [data, setData] = useState([]);
  const [quickFilter, setQuickFilter] = useState("");

  useEffect(() => {
    if (!dataUrl) return;

    FileService.downloadBlobAsJson(dataUrl, headers)
      .then((response) => {
        let rawData = response.data;
        
        // If rawData is a string, parse it
        if (typeof rawData === "string") {
          rawData = JSON.parse(stripJsonComments(rawData));
        }

        //TODO make this a varaible tranformation
        if(transformData) {
          rawData = Object.entries(rawData).flatMap(([type, regions]) =>
            regions.map(region => ({ Type: type, Region: region }))
          );
        }

        // If a path is provided, drill down into the data
        let finalData = rawData;
        if (path && path.trim() !== "") {
          finalData = path.split('.').reduce((acc, key) => (acc && acc[key]) ? acc[key] : [], rawData);
        }

        // Ensure finalData is an array
        if (!Array.isArray(finalData)) {
          finalData = [finalData];
        }

        // If handleStringArrays is true and finalData is an array of strings, transform it
        if (
          handleStringArrays &&
          finalData.length > 0 &&
          finalData.every(item => typeof item === "string")
        ) {
          // Transform ["A", "B"] into [{ code: "A" }, { code: "B" }]
          finalData = finalData.map(item => ({ [stringColumnField]: item }));
        }

        setData(finalData);
      })
      .catch((e) => {
        console.error("Failed to fetch data:", e);
      });
  }, [dataUrl, headers, path, handleStringArrays, stringColumnField]);

  const onQuickFilterChange = (e) => {
    setQuickFilter(e.target.value);
  };

  // If we're handling string arrays and the data is indeed a string array transformed into objects,
  // and no columns were provided, let's define a single column
  const finalColumns = (handleStringArrays && data.length > 0 && typeof data[0] === "object" && Object.keys(data[0]).length === 1 && columns.length === 0) 
    ? [{ headerName: stringColumnHeader, field: stringColumnField, filter: true, sortable: true, resizable: true }]
    : columns;

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        boxSizing: "border-box",
        padding: "20px",
      }}
    >
      <h1>{title}</h1>
      <p className="text-gray">{description}</p>
      <input
        type="text"
        placeholder="Search..."
        onChange={onQuickFilterChange}
        style={{ marginBottom: "10px", padding: "5px", width: "200px" }}
      />

      <div className="ag-theme-alpine" style={{ height, width }}>
        <AgGridReact
          quickFilterText={quickFilter}
          rowData={data}
          columnDefs={finalColumns}
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
          }}
        />
      </div>
    </div>
  );
};

export default BaseAgGridPage;
